import { Button, Form, Skeleton, Radio, Col, Row } from "antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { FC, Fragment, useState, useEffect, Dispatch, useRef } from "react";
import BankIcon from "./../../../assets/icons/multi color icons - svg/bank details.svg";
import InputField from "../InputField";
import StyledModal from "../StyledModal";
import WalletIcon from "./../../../assets/icons/multi color icons - svg/dynamic wallet - light blue.svg";
import WalletStaticIcon from "./../../../assets/icons/multi color icons - svg/static wallet - blue.svg";
import MPesaIcon from "./../../../assets/icons/single color icons - svg/Security check.svg";
import * as yup from "yup";
import "./addWalletBalance.scss";
import { inputNumberConvention, numberConvention } from "../../utils/numberConvention";
import { Wallet } from "../../../models/Wallet/wallet.model";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";
import PaymentProcessingModal from "../PaymentProcessingModal";
import { useHistory } from "react-router";
import DropdownField from "../DropdownField";
import customerService from "../../../services/CustomerService/customer.service";
import { CustomerPayment } from "../../../models/Customer/customerPayment.model";
import WalletService from "../../../services/WalletService/wallet.service";
import Logo from "../../../assets/icons/logo - svg/mpesa.png";
import { Withdraw } from "../../../models/Withdraw/withdraw.model";
import { REGEX } from "../../utils/regex";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { Customer } from "../../../models/Customer/customer.model";
import OTPForm from "../../../views/Auth/LoginForm/OTPForm";
import UIModal from "../UIModal";
import { CustomerTypes } from "../../../enums/customerTypes";
import { AuthState } from "../../../store/reducers/authReducer";
import OtpInput from "react-otp-input";
interface WalletBalanceProps extends TermsAndConditionsReducerProps {
  wallet?: Wallet;
  loading: boolean;
  customer: Customer;
  fetchRequests: () => void
  addBankDetailsHandler: () => void,
  userType :CustomerTypes
}

const withdrawalValidationSchema = yup.object().shape({
  paymentMode: yup
    .number()
    .required("Mode of payment is required"),
  mobileNumber: yup.string()
    .ensure().when('paymentMode', {
      is: (paymentModeId) => paymentModeId === 2,
      then: yup
        .string()
        .required("Mobile number is required!")
        .matches(REGEX.NUMERIC, "Mobile number should be of numeric")
        .min(9, "Mobile number must be at least 9 characters")
        .max(10, "Mobile number must be at least 10 characters")
    }),
});
const WalletBalance: FC<WalletBalanceProps> = (props) => {
  const { addMoneyWallet, fetchPaymentMethod, paymentMethod } =
    customerService();

  const { getWalletDetails, wallet, submitting, createOTPRequest, verifyCreateWithdraw, isSecondarySubmitting } = WalletService();
  const history = useHistory();
  const customerId = history.location.pathname.split("/").pop();

  const { loading, showTermsAndConditions, accept, customer, fetchRequests, addBankDetailsHandler,userType } = props;

  const [withdrawAmount, setWithdrawAmount] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>();
  const [withdrawConfirmationVisibility, setWithdrawConfirmationVisibility] = useState<boolean>(false)

  const formikRef = useRef<FormikProps<any>>(null)
  const withdrawFormikRef = useRef<FormikProps<any>>(null)

  const disableActionEvents: boolean = userType === CustomerTypes.DIGITAL;

  useEffect(() => {
    fetchPaymentMethod();
    customerId && getWalletDetails(customerId);
  }, []);

  const handleChange = (val: string, setState: Dispatch<string>) => {
    const value = val.replace(/\D/g, "");
    const number = parseInt(value === "" ? "1" : value);
    if (isNaN(number)) {
      return;
    }
    const formattedNumberString = inputNumberConvention(number) + " KES";
    setState(formattedNumberString);
  };

  const handleBackspace = (e: any, setState: Dispatch<(prev: any) => string | undefined>) => {
    if (e.keyCode === 8) {
      setState((prev) => {
        const value = prev?.replace(/\D/g, "") || "";
        const number = parseInt(value === "" ? "1" : value);
        if (isNaN(number)) {
          return;
        }
        return inputNumberConvention(Math.floor(number / 10)) + " KES";
      });
    }
  };

  const handleVerifyCreateSubmit = async (verifyCreatevalues: Withdraw, formikHelpers: FormikHelpers<Withdraw>) => {
    const response = await verifyCreateWithdraw({
      ...withdrawFormikRef.current?.values,
      ...verifyCreatevalues,
      userId: customer.id || customerId,
      amountRequested: parseInt(withdrawAmount?.replaceAll(" KES", '')?.replaceAll(",", "") || '0')
    });
    if (response) {
      fetchRequests()
      withdrawFormikRef.current?.resetForm()
      formikHelpers.resetForm()
      setWithdrawConfirmationVisibility(false)
      modalCloseHandler()
    }
  };

  const handleSubmit = async (values: CustomerPayment) => {
    values.amount = amount?.split(" ")[0].replace(/,/g, "");
    addMoneyWallet(values, () => setModalVisible(false));
  };
  const initialValues: CustomerPayment = {
    amount: amount,
    comment: "",
    paymentMethodId: undefined,
    transactionRef: "",
    userId: customerId,
  };
  const formikProps = {
    initialValues,
    onSubmit: handleSubmit,
  };

  const verifyCreateFormikProps = {
		initialValues: { ...withdrawFormikRef.current?.values },
		onSubmit: handleVerifyCreateSubmit,
	};


  const withdrawalFormikProps = {
    initialValues: new Withdraw(),
    onSubmit: () => undefined,
    innerRef: withdrawFormikRef,
    validationSchema: withdrawalValidationSchema,
    enableReinitialize: true
  };

  const modalCloseHandler = () => {
    setAmount(undefined)
    setModalVisible(false)
    setWithdrawModalVisible(false)
    setWithdrawAmount(undefined)
    formikRef.current?.resetForm()
  }

  const WalletActionTemplate = (
    <Fragment>
      <div className="main-content">
        <p className="title">
          {`Enter the amount to be added in your wallet
                    `}
        </p>
        <div className="wallet-form">
          <input
            type="text"
            className="wallet-form--field"
            placeholder="0 KES"
            value={amount}
            name="amount"
            onKeyDown={(e) => handleBackspace(e, setAmount)}
            onChange={(e) => handleChange(e.target?.value, setAmount)}
          />
        </div>
      </div>

      <div className="footer">
        <Formik {...formikProps}>
          {({ isValid, dirty, setFieldValue, values }) => {
            return (
              <Form className="wallet-form">
                <DropdownField
                  title="Mode of Payment"
                  optionLabelProp="label"
                  placeHolder={"  Select"}
                  name="paymentMethodId"
                  options={paymentMethod.map((payment) => ({
                    label: payment.name,
                    value: payment.id,
                  }))}
                  showSearch={false}
                  value={values?.paymentMethodId}
                  onChange={(value) => {
                    setFieldValue("paymentMethodId", value);
                  }}
                />

                <InputField
                  title="Transaction ID"
                  type="text"
                  name="transactionRef"
                  placeholder="Enter Transaction Id"
                />

                <InputField
                  title="Comments"
                  className="input_comment"
                  type="textarea"
                  name="comment"
                  placeholder="Type here..."
                  mb-5
                />

                <div className="styled-modal-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    disabled={
                      !amount || !values.comment || !values.transactionRef
                    }
                    onClick={() => handleSubmit(values)}
                  >
                    {`Add Money to wallet`}
                  </Button>
                  <p className="payment_form__footer">
                    <img src={MPesaIcon} alt="mpesa" />
                    MPESA: 100% Payment Protection
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );

  const generateOTPHandler = async () => {
    try {
      const response = await createOTPRequest({
        ...withdrawFormikRef.current?.values,
        userId: customer.id || customerId,
        amountRequested: parseInt(withdrawAmount?.replaceAll(" KES", '')?.replaceAll(",", "") || '0')
      });
      if (!response) return
      setWithdrawConfirmationVisibility(true)
    } catch (error) {

    }
  }

  const WalletWithdrawActionTemplate = (
    <Fragment>
      <div className="main-content">
        <p className="title">
          {`Enter the amount to be withdrawn from your wallet`}
        </p>
        <div className="wallet-form">
          <input
            type="text"
            className="wallet-form--field"
            placeholder="0 KES"
            value={withdrawAmount}
            name="withdrawAmount"
            onKeyDown={(e) => handleBackspace(e, setWithdrawAmount)}
            onChange={(e) => handleChange(e.target?.value, setWithdrawAmount)}
          />
        </div>
      </div>

      <div className="footer">
        <Formik {...withdrawalFormikProps}>
          {({ isValid, dirty, setFieldValue, submitForm }) => {
            return (
              <Form className="wallet-form">
                <Radio.Group onChange={(e) => setFieldValue("paymentMode", e.target.value)}>
                  <Radio value={1} disabled={!customer?.kycBankDetails?.bankName}>
                    <Row className="withdraw-option-bank">
                      <Col >
                        <p className="option text-left">Withdraw to Bank Account</p>
                        {customer?.kycBankDetails?.bankName
                          ? <p className="bank">Bank {customer?.kycBankDetails?.bankName} - {customer?.kycBankDetails?.accountNumber}</p>
                          : <p>Bank details not available</p>}
                      </Col>
                      <Col>
                        <div className="icon-cover">
                          <img src={BankIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                    {!customer?.kycBankDetails?.bankName && <Col>
                      <label className="bank-details--add" onClick={() => {
                        setWithdrawModalVisible(false)
                        addBankDetailsHandler()
                      }}>
                        Add now
                      </label>
                    </Col>}
                  </Radio>
                  <Radio value={2} >
                    <p className="mpesa-option">
                      Withdraw to MPESA Account
                      <img className="m-pesa-icon" src={Logo} alt="m-pesa-icon" />
                    </p>
                    <div className="input-cover">
                      <InputField
                        type="text"
                        name="mobileNumber"
                        className="mpesa-field lg"
                        placeholder="Enter MPESA mobile number"
                      />
                    </div>
                  </Radio>
                </Radio.Group>

                <div className="styled-modal-btn">
                  <div className="withdraw-footer">
                    <Checkbox
                      className="terms-acceptance--checkbox"
                      name="isAgreed"
                      checked={accept}
                      onChange={showTermsAndConditions}
                    >
                      <span className="terms-acceptance-label">
                        I have read all the{" "}
                        <b onClick={showTermsAndConditions}>
                          Terms and Conditions
                        </b>{" "}
                        and agree to the same
                      </span>
                    </Checkbox>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    disabled={
                      !isValid || !dirty || !accept || !withdrawAmount
                    }
                    loading={isSecondarySubmitting}
                    onClick={generateOTPHandler}
                  >
                    {`Withdraw Amount`}
                  </Button>
                  <p className="payment_form__footer">
                    <img src={MPesaIcon} alt="mpesa" />
                    MPESA: 100% Payment Protection
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );

  return (
    <div className="wallet__container">
      <div className="wallet__dynamic ">
        <div className="wallet__dynamic--balance">
          <div className="wallet__dynamic--wallet-icon">
            <img src={WalletIcon} alt="wallet" />
          </div>
          <div>
            <p className="wallet__dynamic--balance--title">
              Dynamic Wallet Balance
            </p>
            {!loading ? (
              <p className="wallet__dynamic--balance--value">
                {numberConvention(wallet?.dynamicBalance)} KES
              </p>
            ) : (
              <Skeleton.Input active={true} size="large" />
            )}
          </div>
        </div>
        <div className="wallet__dynamic--actions">
          <Button
            className="primary"
            type="primary"
            onClick={() => setModalVisible(true)}
            disabled={userType === CustomerTypes.DIGITAL}
          >
            Add Money
          </Button>
          <Button
            className="default"
            disabled={(wallet?.dynamicBalance || 0) <= 0 || userType === CustomerTypes.DIGITAL}
            onClick={() => setWithdrawModalVisible(true)}
          >
            Withdraw Money
          </Button>
        </div>
      </div>
      <div className="wallet__static">
        <div className="wallet__static--balance">
          <div className="wallet__static--wallet-icon">
            <img src={WalletStaticIcon} alt="wallet" />
          </div>
          <div>
            <p className="wallet__static--balance--title">
              Static Wallet Balance
              {/* <i className="icon-ic-info-24px" /> */}
            </p>
            {!loading ? (
              <p className="wallet__static--balance--value">
                {numberConvention(wallet?.staticBalance)} KES
              </p>
            ) : (
              <Skeleton.Input active={true} size="large" />
            )}
          </div>
        </div>
      </div>
      <StyledModal
        visible={modalVisible}
        title="Add Money to Dynamic wallet"
        className="dark-bg"
        dynamicWalletBalance={wallet?.dynamicBalance || 0}
        staticWalletBalance={wallet?.staticBalance || 0}
        content={WalletActionTemplate}
        okText="Add Money to Wallet"
        onCancel={modalCloseHandler}
      />
      <StyledModal
        visible={withdrawModalVisible}
        title="Withdraw from Dynamic wallet"
        className="dark-bg"
        dynamicWalletBalance={wallet?.dynamicBalance || 0}
        staticWalletBalance={wallet?.staticBalance || 0}
        content={WalletWithdrawActionTemplate}
        okText="Withdraw Amount"
        onCancel={modalCloseHandler}
      />
      <PaymentProcessingModal visible={isProcessing} />
      <Formik {...verifyCreateFormikProps}>
        {({ values, setFieldValue, isValid, dirty, submitForm, resetForm }) => (
          <Form>
            <UIModal
              className="withdraw-req__confirmation"
              visible={withdrawConfirmationVisibility}
              loading={submitting}
              closable={false}
              onCancel={() => {
                resetForm();
                modalCloseHandler();
                setWithdrawConfirmationVisibility(false);
              }}
              onConfirm={() => submitForm()}
              okText="Verify and Create"
              okButtonProps={{
                type: "primary",
                htmlType: "submit",
                disabled: !isValid || !dirty || !!(values.emailOtp && values.emailOtp?.length < 6),
                loading: submitting
              }}
              title="Verify OTP and Create Withdraw Request"
            >
              <>
                <div className="otp__container">
                  <div className="title-text">Enter OTP sent to your mobile</div>
                  <OtpInput
                    containerStyle={{
                      justifyContent: "space-between",
                    }}
                    className="otp__wrapper"
                    value={values.emailOtp}
                    onChange={(otp: string) =>
                      setFieldValue("emailOtp", otp)
                    }
                    numInputs={6}
                  />
                </div>
                <div>Are you sure want to create a withdraw request for {withdrawAmount}?</div>
              </>
            </UIModal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default termsAndConditionsContainer(WalletBalance);
