import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { FC, Fragment, ReactElement, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../../../services/AuthService/auth.service";
import NotificationIcon from "./../../../assets/icons/multi color icons - svg/notificaction.svg"
import "./pageHeader.scss"

interface PageHeaderProps {
    title: string,
    showSubtitles?: boolean,
    titleBadge?: string,
    showWallet?: boolean
    backText?: string
    headerActions?: JSX.Element
}

const PageHeader: FC<PageHeaderProps> = (props) => {

    const history = useHistory()

    const {
        title,
        showWallet = false,
        showSubtitles,
        backText = '',
        headerActions,
    } = props

    const onBack = () => {
        history.goBack()
    }

    return (
        <div className="page-header__container">
            {title && <div className={"page-header__details " + (showSubtitles && 'subtitle-placeholder')}>
                {backText && <p className="page-header__details--back" onClick={onBack}><ArrowLeftOutlined /> {backText}</p>}
                <h2 className="page-header__details--title">{title}
                </h2>
            </div>
            }
            {headerActions}
        </div>
    )
}

export default PageHeader