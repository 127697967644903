import { ButtonProps } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { FC } from 'react'
import "./uiModal.scss"

interface UIModalProps {
    title: string
    okText?: string
    visible: boolean
    loading?: boolean
    closable?: boolean
    centered?: boolean
    className?: string
    onCancel: () => void
    onConfirm: () => void
    footer?: React.ReactChild | false
    okButtonProps?: ButtonProps
}

const UIModal: FC<UIModalProps> = (props) => {

    const {
        title,
        footer,
        visible,
        okText,
        loading,
        closable,
        children,
        centered,
        onCancel,
        className,
        onConfirm,
        okButtonProps,
    } = props

    return (
        <Modal
            footer={footer}
            visible={visible}
            destroyOnClose
            okText={okText}
            closable={closable}
            onOk={onConfirm}
            centered={centered}
            onCancel={onCancel}
            confirmLoading={loading}
            okButtonProps={okButtonProps}
            className={`ui-modal ${className}`}
        >
            <h2>{title}</h2>
            {children}
        </Modal>
    )
}

export default UIModal