import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import termsAndConditionsReducer, { TermsAndConditionsState } from "./termsAndConditionsReducer";

export interface RootReducerProps {
    auth: AuthState,
    termsAndCondtions: TermsAndConditionsState
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    termsAndCondtions: termsAndConditionsReducer
});
export default RootReducer;