import { Button, Modal } from "antd";
import { Formik, Form } from "formik";
import React from "react";
import InputField from "../InputField";
import CustomerService from "../../../services/CustomerService/customer.service";
import { changePasswordValidation } from "./changePasswordValidation";
import "./changePasswordModal.scss";

interface ChangePasswordProps {
  showModal: boolean;
  handleModalCancel: () => void;
}

export const ChangePasswordModal = (props: ChangePasswordProps) => {
  const { showModal, handleModalCancel } = props;
  const { changePassword, changePasswordButtonLoading } = CustomerService();

  const initialValues = {
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  const submitHandler = async (data: typeof initialValues) => {
    const payload = {
      current_password: data.currentPassword,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    };

    const response = await changePassword(payload);
    if (response === 200) {
      handleModalCancel();
    }
  };
  return (
    <Modal
      className="change-password-modal"
      title={"Change Password"}
      visible={showModal}
      onCancel={handleModalCancel}
      footer={null}
      centered
      key={showModal ? "open" : "closed"}
    >
      {showModal && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={changePasswordValidation}
        >
          {({ isValid, dirty}) => (
            <>
              <Form>
                <InputField
                  name="currentPassword"
                  title="Current Password"
                  placeholder="Current Password"
                  type="password"
                />
                <InputField
                  name="password"
                  title="New Password"
                  placeholder="New Password"
                  type="password"
                />
                <InputField
                  name="passwordConfirmation"
                  title="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                />
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="update-password-btn"
                  disabled={!isValid || !dirty}
                  loading={changePasswordButtonLoading}
                >
                  Update
                </Button>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};
