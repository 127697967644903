import { alias, primitive, serializable } from "serializr";

export class SharePrice {

    @serializable(alias("price", primitive()))
    price?: number

    @serializable(alias("name", primitive()))
    name?: string

    @serializable(alias("otp", primitive()))
    otp?: string

    @serializable(alias("effective_date", primitive()))
    date?: string

    @serializable(alias("created_at", primitive()))
    createdDate?: string

}