import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, List, Menu, Modal } from "antd";
import { Formik } from "formik";
import React, { FC, Fragment } from "react";
import { numberConvention } from "../../utils/numberConvention";
import CheckboxField from "../CheckboxField";
import InputField from "../InputField";
import WalletIcon from "./../../../assets/icons/multi color icons - svg/dynamic wallet -  white.svg";
import WalletStaticIcon from "./../../../assets/icons/multi color icons - svg/static wallet - white.svg";
import "./styledModal.scss"

interface StyleModalProps {
    visible: boolean
    title: string;
    dynamicWalletBalance: number;
    staticWalletBalance: number;
    content?: any,
    okText: string
    className?: string,
    confirmLoading?: boolean
    onCancel: () => void
    maskClosable?: boolean
    contentTemplate?: any
}

const StyledModal: FC<StyleModalProps> = (props) => {

    const {
        visible,
        title,
        dynamicWalletBalance,
        staticWalletBalance,
        content,
        className,
        confirmLoading = false,
        okText,
        onCancel,
        maskClosable = false,
    } = props


    const agreeTermsAndConditions = [
        {
            label: <span className="terms-acceptance-label">I have read all the <b>Terms and Conditions</b> and agree to the same</span>,
            value: true
        }
    ]

    const handleSubmit = (values: { isAgreed: boolean }) => {
    }

    const formikProps = {
        initialValues: {
            isAgreed: false
        },
        onSubmit: handleSubmit,
    };


    return (
        <Modal
            className={"styled-modal " + className}
            title={
                <Fragment>
                    <h2>{title}</h2>
                    <div>
                        <img src={WalletIcon} alt="dynamic" />
                        <p className="text">Dynamic Wallet Balance </p>
                        <p className="balance">{numberConvention(dynamicWalletBalance)} KES</p>
                    </div>
                    <div>
                        <img src={WalletStaticIcon} alt="dynamic" />
                        <p className="text">Static Wallet Balance </p>
                        <p className="balance">{numberConvention(staticWalletBalance)} KES</p>
                    </div>
                </Fragment>
            }
            visible={visible}
            onOk={() => { }}
            maskClosable={maskClosable}
            okText={okText}
            destroyOnClose
            onCancel={onCancel}
            confirmLoading={confirmLoading}
        >
            {content}
        </Modal>
    )
}

export default StyledModal