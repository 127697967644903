import { Form, Row, Col, List } from 'antd'
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React, { FC, useEffect, useRef } from 'react'
import SubscriptionService from '../../../services/SubscriptionService/subscription.service'
import { numberConvention } from '../../utils/numberConvention'
import ButtonComponent from '../ButtonComponent'
import DropdownField from '../DropdownField'
import UIModal from '../UIModal'
import "./subscriptionModal.scss"

interface SubscriptionModalProps {
    visible: boolean,
    customerId: string
    closeHandler: (response?: boolean) => void
}

const SubscriptionModal: FC<SubscriptionModalProps> = (props) => {

    const {
        visible,
        customerId,
        closeHandler,
    } = props

    const {
        loading,
        subscriptions,
        paymentDetails,
        upgradeSubscription,
        fetchSubscriptionsMeta,
        fetchSubscriptionDetails,
    } = SubscriptionService()

    const formikRef = useRef<any>()

    useEffect(() => {
        visible &&
            fetchSubscriptionsMeta(customerId)
    }, [visible])

    const upgradeSubscriptionHandler = async (values: FormikValues, helpers: FormikHelpers<any>) => {
        if (!values.subscriptionId) return

        const response = await upgradeSubscription(customerId, values.subscriptionId)
        if (!response) return

        helpers?.resetForm()
        closeHandler(true)
    }


    return (
        <UIModal
            title={'Edit Subscription for customer'}
            visible={visible}
            footer={false}
            onCancel={() => closeHandler()}
            onConfirm={() => { }}>
            <Formik
                initialValues={{ subscriptionId: '' }}
                onSubmit={upgradeSubscriptionHandler}
                innerRef={formikRef}
            >
                {({ setFieldValue, values, submitForm }) => {
                    return <Form>
                        <Row justify='center' className='subscription__form'>
                            <Col span={24}>
                                <DropdownField
                                    title="Upgrade To"
                                    optionLabelProp="label"
                                    placeHolder={"Select"}
                                    name="subscriptionId"
                                    options={subscriptions}
                                    showSearch={false}
                                    value={values?.subscriptionId}
                                    onChange={(value) => {
                                        setFieldValue("subscriptionId", value);
                                        fetchSubscriptionDetails({
                                            customerId,
                                            subscriptionId: value
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={24} className="mb-5">
                                <List>
                                    <List.Item>
                                        <span>Joining Fee (Difference from current plan)</span>
                                        <span>
                                            {numberConvention(paymentDetails?.joiningFee || 0)}
                                        </span>
                                    </List.Item>
                                    <List.Item>
                                        <span>Annual Fee (Pro-rated difference from current plan)</span>
                                        <span>
                                            {numberConvention(paymentDetails?.annualFee || 0)}
                                        </span>
                                    </List.Item>
                                    <List.Item>
                                        <span>Amount to be paid</span>
                                        <span className='text-bold'>
                                            {numberConvention(paymentDetails?.amountToBePaid || 0)}
                                        </span>
                                    </List.Item>
                                    <List.Item>
                                        <span>Dynamic wallet balance</span>
                                        <span className='text-bold text-success'>
                                            {numberConvention(paymentDetails?.balance || 0)}
                                        </span>
                                    </List.Item>
                                </List>
                            </Col>
                            <Col span={18}>
                                <ButtonComponent
                                    disabled={!values?.subscriptionId || !paymentDetails?.amountToBePaid || (paymentDetails?.amountToBePaid || 0) > (paymentDetails?.balance || 0)}
                                    className='confirm-btn'
                                    htmlType='submit'
                                    onClick={submitForm}
                                    loading={loading}
                                    type='primary'>Confirm Upgrade</ButtonComponent>
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
        </UIModal>
    )
}

export default SubscriptionModal