import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as termsAndConditionsFunctions from "../actions/termsAndConditions";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => ({
    ...ownProps,
    show: state.termsAndCondtions.show,
    accept: state.termsAndCondtions.accept,
    noPrompt: state.termsAndCondtions.noPrompt
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(termsAndConditionsFunctions, dispatch);

const termsAndConditionsContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default termsAndConditionsContainer;
