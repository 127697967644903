import { ColumnsType } from "antd/lib/table";
import { Payment } from "../../../models/Payments/payment.model";
import { Popover, Space } from "antd";
import Dustbin from "../../../assets/icons/dustbin-red.svg";
import { ReactComponent as MoreOption } from "../../../assets/icons/more-option.svg";
import React from "react";

export const fetchPaymentColumns = (
  setSelectedPayment: React.Dispatch<React.SetStateAction<Payment | null>>
) => {
  const columns: ColumnsType<Payment> = [
    {
      title: "Customer Id",
      dataIndex: "customerId",
      key: "customer_id",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      render: (record) => {
        return record.isCancellable ? (
          <Popover
            placement="bottomRight"
            overlayClassName="payments__more-icon"
            content={
              <div onClick={() => setSelectedPayment(record)}>
                <Space size={15}>
                  <img src={Dustbin} alt="dustbin-icon" />
                  Cancel Payment
                </Space>
              </div>
            }
          >
            <div className="payments__more-icon--icon">
              <MoreOption />
            </div>
          </Popover>
        ) : null;
      },
    },
  ];

  return {
    columns,
  };
};
