import { serializable, alias, primitive } from "serializr";

export class AccountManager {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("uid", primitive()))
  uid?: string;

  @serializable(alias("is_blacklisted", primitive()))
  isBlacklisted?: 0 | 1;

  @serializable(alias("kyc_complete_status", primitive()))
  kycCompleteStatus?: number;

  @serializable(alias("is_kyc_accepted", primitive()))
  isKycAccepted?: number;

  @serializable(alias("member_since", primitive()))
  memberSince?: string;

  @serializable(alias("password_changed_at", primitive()))
  passwordChangedAt?: string;

  @serializable(alias("registration_id", primitive()))
  registrationId?: string;

  @serializable(alias("subscription_auto_renewal", primitive()))
  subscriptionAutoRenewal?: number;

  @serializable(alias("auto_dividend_invest_option", primitive()))
  autoDividendInvestOption?: 0 | 1;

  @serializable(alias("account_manager_id", primitive()))
  accountManagerId?: string;
}
