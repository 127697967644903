import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED, REQUEST_LOGOUT } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { CUSTOMERS } from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import LocalStorage from "../../shared/LocalStorage";

const UserService = () => {
	const history = useHistory();

	const [user, setUser] = useState<User>();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const triggerOtp = async (data: User) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post(ApiRoutes.USER_LOGIN_GET_OTP, data)
		} catch (error) {
			throw error
		} finally {
			setLoading(false);
		}
	};

	const verifyOtp = async (data: User) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post(ApiRoutes.USER_LOGIN, data)
			LocalStorage.setItem(
				"vuka-token",
				response.data["access_token"]
			);
			await getUserDetails()
			history.push(CUSTOMERS);
		} catch (error) {
			throw error
		} finally {
			setLoading(false);
		}
	}


	const getUserDetails = async (checkTokenExists = false, onError = () => { }) => {
		try {
			// console.log(checkTokenExists, !LocalStorage.getItem("vuka-token"));

			if (checkTokenExists && !LocalStorage.getItem("vuka-token")) return false;
			const response = await axiosInstance.get(ApiRoutes.GET_USER);
			if (response.data?.success) {
				const user = deserialize(User, response.data.user);
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: user,
					},
				});
				setUser(user);

				return user;
			}
			Notification({
				message: "",
				description: response.data?.message,
				type: NotificationTypes.SUCCESS,
			});
		} catch (error) {
			onError();
		} finally {
		}
	};

	const logoutUser = async () => {
		try {
			const response = await axiosInstance.put(ApiRoutes.USER_LOGOUT);
			store.dispatch({
				type: REQUEST_LOGOUT,
				payload: "Request for logout",
			});
			LocalStorage.clearSenstive();
			Notification({
				message: "",
				description: response.data?.message,
				type: NotificationTypes.SUCCESS,
			});
		} catch (error) {
		} finally {
		}
	};

	const requestResetPasswordLink = async (email: string, disableNotification?: boolean) => {
		setLoading(true)
		try {
			const data = { email }
			const response = await axiosInstance.put(ApiRoutes.FORGOT_PASSWORD, data)
			if (response.data.success && !disableNotification) {
				Notification({
					message: "",
					description: response.data?.message,
					type: NotificationTypes.SUCCESS,
				});
			}
			return true
		} catch (error) {
			throw error
		} finally {
			setLoading(false)
		}
	}

	const resetPassword = async (url: string, password: string) => {
		setLoading(true)
		try {
			if (!url) {
				const errorMessage = "Please use the link sent to your mail id"
				Notification({
					message: "",
					description: errorMessage,
					type: NotificationTypes.ERROR,
				});
				throw new Error(errorMessage)
			}
			const response = await axiosInstance.put(url, { password })
			if (response.data.success) {
				Notification({
					message: "",
					description: response.data?.message,
					type: NotificationTypes.SUCCESS,
				});
			}
			return true
		} catch (error) {
			throw error
		} finally {
			setLoading(false)
		}
	}

	return {
		user,
		error,
		loading,
		verifyOtp,
		triggerOtp,
		logoutUser,
		resetPassword,
		getUserDetails,
		requestResetPasswordLink,
	};
};

export default UserService;
