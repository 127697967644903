import React, { FC } from "react";
import { Row, Col, Popover } from "antd";
import "./navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import DashboardIcon from "./../../../assets/icons/multi color icons - svg/dashboard blue.svg";
import DashboardWhiteIcon from "./../../../assets/icons/multi color icons - svg/dashboard white.svg";
import TicketsWhiteIcon from "./../../../assets/icons/multi color icons - svg/tickets white.svg";
import TicketsIcon from "./../../../assets/icons/multi color icons - svg/tickets.svg";
import HoldingsIcon from "./../../../assets/icons/multi color icons - svg/holdings.svg";
import ConfigIcon from "./../../../assets/icons/multi color icons - svg/config.svg";
import ConfigWhiteIcon from "./../../../assets/icons/multi color icons - svg/config white.svg";
import ReportWhiteIcon from "./../../../assets/icons/multi color icons - svg/report white.svg";
import CustomersWhiteIcon from "./../../../assets/icons/multi color icons - svg/customers white.svg";
import CustomersIcon from "./../../../assets/icons/multi color icons - svg/customers.svg";
import WithdrawWhiteIcon from "./../../../assets/icons/multi color icons - svg/withdraw req white.svg";
import WithdrawIcon from "./../../../assets/icons/multi color icons - svg/withdraw req.svg";
import CommuncationIcon from "./../../../assets/icons/multi color icons - svg/communication.svg";
import CommuncationWhiteIcon from "./../../../assets/icons/multi color icons - svg/communication white.svg";
import ReportIcon from "./../../../assets/icons/multi color icons - svg/report.svg";
import ReferIcon from "./../../../assets/icons/multi color icons - svg/refer.svg";
import LogoutIcon from "./../../../assets/icons/multi color icons - svg/logout.svg";
import HoldingsWhiteIcon from "./../../../assets/icons/multi color icons - svg/holdings selected.svg";
import ReferWhiteIcon from "./../../../assets/icons/multi color icons - svg/refer selected.svg";
import AccountManagerIcon from "./../../../assets/icons/multi color icons - svg/account-manager.svg";
import AccountManagerWhiteIcon from "./../../../assets/icons/multi color icons - svg/account-manager white.svg";
import WalletIconWhite from "../../../assets/icons/wallet-white.svg"
import WalletIcon from "../../../assets/icons/wallet.svg"
import {
  HOME,
  WALLET,
  WITHDRAW,
  REPORT,
  REFER,
  PROFILE,
  CUSTOMERS,
  ORDERS,
  ACCOUNT_MANAGER,
} from "../../../routes/routeConstants/appRoutes";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { AuthState } from "../../../store/reducers/authReducer";
import AuthService from "./../../../services/AuthService/auth.service";
import { CustomerTypes } from "../../../enums/customerTypes";
import Profile from "../../../assets/icons/profile-main.svg";
import DownArrow from "../../../assets/icons/down-arrow.svg";
import Mail from "../../../assets/icons/mail.svg";
import Phone from "../../../assets/icons/phone.svg";
import Lock from "../../../assets/icons/lock.svg";
import { ChangePasswordModal } from "../ChangePasswordModal";

interface NavItems {
  label: string;
  icon?: string;
  activeIcon?: string;
  route: string;
  key: string;
  disabled?: boolean;
  hidden?: boolean;
}

interface NavbarProps extends AuthState {}

const Navbar: FC<NavbarProps> = (props) => {
  const { user } = props;

  const history = useHistory();

  const [activeRoute, setActiveRoute] = useState<string>();
  const [logoutRequested, setLogoutRequested] = useState<boolean>(false);
  const { logoutUser } = AuthService();
  const [passwordModalVisibility, setPasswordModalVisibility] =
    useState<boolean>(false);

  const NavItems: NavItems[] = [
    {
      label: "Dashboard",
      icon: DashboardIcon,
      activeIcon: DashboardWhiteIcon,
      route: HOME,
      key: HOME,
      // hidden: user?.role !== CustomerTypes.ADMIN,
      hidden: true,
      disabled: true,
    },
    {
      label: "Customers",
      icon: CustomersIcon,
      activeIcon: CustomersWhiteIcon,
      route: CUSTOMERS,
      key: CUSTOMERS,
    },
    {
      label: "Orders",
      icon: HoldingsIcon,
      activeIcon: HoldingsWhiteIcon,
      route: ORDERS,
      key: "orders",

      hidden: user?.role !== CustomerTypes.ADMIN,
    },
    {
      label: "Tickets",
      icon: TicketsIcon,
      activeIcon: TicketsWhiteIcon,
      route: WALLET,
      key: WALLET,
      disabled: true,
      hidden: true,
      // hidden: user?.role !== CustomerTypes.ADMIN,
    },
    {
      label: "Reports",
      icon: ReportIcon,
      activeIcon: ReportWhiteIcon,
      route: REPORT,
      key: REPORT,
      hidden: user?.role !== CustomerTypes.ADMIN,
      disabled: false,
    },
    {
      label: "Communication",
      icon: CommuncationIcon,
      activeIcon: CommuncationWhiteIcon,
      route: PROFILE,
      key: PROFILE,
      // hidden: user?.role !== CustomerTypes.ADMIN,
      hidden: true,
      disabled: true,
    },
    {
      label: "Withdraw Req",
      icon: WithdrawIcon,
      activeIcon: WithdrawWhiteIcon,
      route: WITHDRAW,
      key: WITHDRAW,
      hidden: user?.role !== CustomerTypes.ADMIN,
    },
    {
      label: "Payments",
      route: AppRoutes.PAYMENT,
      key: AppRoutes.PAYMENT,
      icon : WalletIcon,
      activeIcon: WalletIconWhite,
      hidden: user?.role !== CustomerTypes.ADMIN
    },
    {
      label: "Account Manager",
      icon: AccountManagerIcon,
      activeIcon: AccountManagerWhiteIcon,
      route: ACCOUNT_MANAGER,
      key: ACCOUNT_MANAGER,
      hidden: user?.role !== CustomerTypes.ADMIN,
      disabled: false,
    },
    {
      label: "Invite Friends",
      icon: ReferIcon,
      activeIcon: ReferWhiteIcon,
      route: REFER,
      key: REFER,
    },
    {
      label: "Configurations",
      icon: ConfigIcon,
      activeIcon: ConfigWhiteIcon,
      route: AppRoutes.CONFIG,
      key: AppRoutes.CONFIG,
      hidden: user?.role !== CustomerTypes.ADMIN,
    },

  ];

  history.listen((data) => {
    setActiveRoute(data.pathname);
  });

  useEffect(() => {
    setActiveRoute(history.location.pathname);
  }, []);

  const handleLogout = async () => {
    setLogoutRequested(true);
    try {
      await logoutUser();
      history.push(AppRoutes.LOGIN);
    } catch (error) {}
  };

  const togglePasswordModal = () => {
    setPasswordModalVisibility((prevVisible) => !prevVisible);
  };

  const popoverContent = (
    <div>
      <Row>
        <Col className="popover__user-name-col" span={24}>
          <h3 className="popover__user-name">{user?.username}</h3>
        </Col>
        <Col className="popover__user-info-col" span={24}>
          <h4 className="popover__user-uid">{user?.uid}</h4>
          <h4 className="popover__user-role">• {user?.role}</h4>
        </Col>
        <Col className="popover__user-email-col" span={24}>
          <img src={Mail} alt="" className="popover__email-icon" />
          <h4 className="popover__user-email">{user?.email}</h4>
        </Col>
        <Col className="popover__user-phone-col" span={24}>
          <img src={Phone} alt="" className="popover__phone-icon" />
          <h4 className="popover__user-phone">{user?.mobileNumber}</h4>
        </Col>
        <Col span={24}>
          <hr className="popover__user-divider" />
        </Col>
        <Col className="popover__change-password-col">
          <img src={Lock} alt="" className="popover__change-password-col" />
          <h4
            className="popover__change-password"
            onClick={togglePasswordModal}
          >
            Change Password
          </h4>
        </Col>
        <Col className="popover__logout-col" span={24}>
          <img src={LogoutIcon} alt="" />
          <h4 className="popover__user-logout" onClick={handleLogout}>
            Logout
          </h4>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="sidebar__container">
      <div className="sidebar__logo"></div>
      {user?.role && (
        <div className="sidebar__items">
          {NavItems.map((nav) => {
            if (nav.hidden) return null;
            return (
              <div
                style={{
                  pointerEvents: nav.disabled ? "none" : "all",
                }}
                key={nav.key}
                className={
                  "sidebar__items--item " +
                  (activeRoute?.includes(nav.route) && " active")
                }
                onClick={() => history.push(nav.route)}
              >
                <img
                  src={
                    activeRoute?.includes(nav.route) ? nav.activeIcon : nav.icon
                  }
                  alt={nav.label}
                />
                <span>{nav.label}</span>
              </div>
            );
          })}
          <div className="sidebar__items--item sidebar__profile-container">
            <Popover
              placement="topRight"
              content={popoverContent}
              trigger={"click"}
              overlayClassName="sidebar__profile-popover"
              // className="sidebar__profile-popover"
            >
              <Row className="user-card">
                <Col span={4} className="user-wrapper">
                  <img src={Profile} />
                </Col>
                <Col span={13}>
                  <p className="user-name">{user?.username}</p>
                  <p className="role">{user?.role}</p>
                </Col>
                <Col span={2}>
                  <img src={DownArrow} />
                </Col>
              </Row>
            </Popover>
          </div>
          <ChangePasswordModal
            showModal={passwordModalVisibility}
            handleModalCancel={togglePasswordModal}
          />
          <div className="sidebar__items--item animator"></div>
        </div>
      )}{" "}
    </div>
  );
};

export default AuthContainer(Navbar);
