import { DownOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InternalUser } from "../../../../models/InternalUser/internalUser.modal";
import ConfigurationService from "../../../../services/ConfigurationService/configuration.service";
import AlertModal from "../../../../shared/components/AlertModal";
import DeleteConfirmation from "../../../../shared/components/DeleteConfirmation";
import SearchBar from "../../../../shared/components/SearchBar";
import TableComponent from "../../../../shared/components/TableComponent";
import InternalUserForm from "./InternalUserForm";
import "./internalUsers.scss";
import moment from "moment";

interface InternalUsersProps { }

const InternalUsers = (props: InternalUsersProps) => {
  const [searchString, setSearchString] = useState<string>();
  const [internalUserForm, setInternalUserForm] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<InternalUser>();
  const [configFilterSearch, setconfigFilterSearch] = useState({
    page: "",
    search: "",
    // sort: "",
    // sort_by: "",
    // sort_direction: "",
    // filters: {},
  });

  const {
    loading,
    internalUsers,
    internalUsersCreatableRoles,
    fetchInternalUsers,
    createInternalUser,
    updateInternalUser,
    fetchCreatableUserMeta,
    pageData,
  } = ConfigurationService();

  useEffect(() => {
    fetchCreatableUserMeta();
    fetchInternalUsers();
  }, []);

  useEffect(() => {
    fetchInternalUsers(configFilterSearch);
  }, [configFilterSearch]);

  useEffect(() => {
    const debouceTimeout = setTimeout(() => {
      fetchInternalUsers(configFilterSearch);
    }, 800);

    return () => {
      clearTimeout(debouceTimeout);
    };
  }, [searchString]);

  const internalFormCloseHandler = () => {
    setInternalUserForm(false);
    setSelectedUser(undefined);
  };

  const formSubmitHandler = async (
    values: InternalUser,
    resetForm: Function
  ) => {
    try {
      if (values?.id) {
        await updateInternalUser(values);
        resetForm();
        fetchInternalUsers();
        internalFormCloseHandler();
      } else {
        await createInternalUser(values);
        resetForm();
        fetchInternalUsers();
        internalFormCloseHandler();
      }
    } catch (error) { }
  };

  const generateMenu = (internalUser: InternalUser) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSelectedUser(internalUser);
          setInternalUserForm(true);
        }}
      >
        Edit
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "uId",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "isBlacklisted",
      key: "isBlacklisted",
      render: (isBlacklisted: string) =>
        !isBlacklisted ? (
          <span className="kyc--status accepted">Active</span>
        ) : (
          <span className="kyc--status rejected">Inactive</span>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text: string, record: InternalUser) => (
        <Dropdown overlay={() => generateMenu(record)} trigger={["click"]}>
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortAlias: any = { ascend: "asc", descend: "desc" };
    setconfigFilterSearch({
      ...configFilterSearch,
      sort_direction: sortAlias[sorter.order] ?? "",
      // sort_by: sortRenameUserId(sorter.field) ?? "",
      page: pagination.current,
      ...filters,
    });
    /* fetchCustomers(filterSearch); */
  };

  return (
    <div className="internal-users__container">
      <Row className="internal-users--table-header" justify="space-between">
        <Col>
          <SearchBar
            placeholder="Search by customer name, id"
            filters={false}
            onChange={(value) => {
              setconfigFilterSearch({
                ...configFilterSearch,
                search: value,
              });
              /*  setFieldValue("filters." + key, value); */
            }}
          />
        </Col>
        <Col>
          <Button onClick={() => setInternalUserForm(true)} type="primary">
            New User
          </Button>
        </Col>
      </Row>
      <InternalUserForm
        title="New User"
        visible={internalUserForm}
        closeHandler={internalFormCloseHandler}
        userRoles={internalUsersCreatableRoles}
        data={selectedUser}
        loading={loading}
        submitHandler={formSubmitHandler}
      />
      <TableComponent
        className="internal-users--table"
        loading={loading}
        columns={columns}
        dataSource={internalUsers}
        rowKey="uId"
        pagination={{
          position: ["bottomRight"],
          pageSize: pageData?.perPage,
          total: pageData?.total,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default InternalUsers;
