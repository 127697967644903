import React, { FC } from "react";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";
import "./termsAndPolicy.scss";

interface TermsAndPolicyProps extends TermsAndConditionsReducerProps {

}

const TermsAndPolicy: FC<TermsAndPolicyProps> = (props) => {
    const { showTermsAndConditionsWithoutPrompt } = props;
    return (
        <div className="text-center primary-black mt-5 terms-and-policy">
            <span onClick={showTermsAndConditionsWithoutPrompt} className="cursor-pointer login-link">Privacy Policy</span>
            <div className="dot" />
            <span
                className="cursor-pointer login-link"
                onClick={showTermsAndConditionsWithoutPrompt}
            >
                Terms and Conditions
            </span>
        </div>
    );
};

export default termsAndConditionsContainer(TermsAndPolicy);
