import React, { FC, useEffect, useState } from "react";
import CustomerService from "../../../../services/CustomerService/customer.service";
import TableComponent from "../../../../shared/components/TableComponent";
import { allCustomersColumns } from "./accountManagerTable";
import { Tabs, Button, Popover, Row, Col } from "antd";
import PageHeader from "../../../../shared/components/PageHeader";
import "./accountManagerList.scss";
import { useHistory, useLocation } from "react-router-dom";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import SearchBar from "../../../../shared/components/SearchBar";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import { ColumnsType } from "antd/lib/table";
import FilterForm from "./FilterForm";
import AllocateForm from "./AllocateForm";
import { FetchQueryParams } from "../../../../shared/types/fetchQueryParams.type";
import { filterInitialValues } from "../../../../shared/constants/filterInitialValues.data";
import { getPaginationFooter } from "../../../../shared/utils/getPaginationFooter";

interface AccountManagerListProps extends AuthState {}

const accountManagerTabs = [
  {
    key: "1",
    title: "All Customers",
    hash: "all-customers",
  },
];

const sortRenameUserId = (uId: string) => {
  switch (uId) {
    case "uId":
      return "user_id";
    case "name":
      return "name";
    case "role":
      return "role";
    case "joinedDate":
      return "joined_date";
    case "email":
      return "email";
    case "subscription":
      return "subscription";
    case "lastupdate":
      return "last_updated_on";
    default:
      return uId;
  }
};

const AccountManagerList: FC<AccountManagerListProps> = (props) => {
  const [column, setColumn] = useState<ColumnsType<any>>(allCustomersColumns);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [ispopOverVisible, setIspopOverVisible] = useState(false);
  const [filterSearch, setfilterSearch] =
    useState<FetchQueryParams>(filterInitialValues);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

  const { TabPane } = Tabs;
  const history = useHistory();
  const location = useLocation();

  const { fetchCustomers, customers, loading } = CustomerService();

  const fetchNewData = async () => await fetchCustomers(filterSearch);

  useEffect(() => {
    const debouceTimeout = setTimeout(() => {
      if (selectedTab === "1") {
        fetchNewData();
      }
    }, 800);

    return () => {
      clearTimeout(debouceTimeout);
    };
  }, [filterSearch.search]);

  const tabChangeHandler = (key: string) => {
    const hash = accountManagerTabs.find((tab) => tab.key === key)?.hash;
    setSelectedTab(key);
    history.push(AppRoutes.ACCOUNT_MANAGER + `#${hash}`);
    setfilterSearch(filterInitialValues);
  };

  useEffect(() => {
    const tabFromHash =
      accountManagerTabs.find(
        (tab) => tab.hash === location.hash?.replace("#", "")
      ) || accountManagerTabs[0];
    if (selectedTab && selectedTab === tabFromHash?.key) {
      if (selectedTab === "1") {
        setColumn(allCustomersColumns);
        fetchNewData();
      }
    } else {
      setSelectedTab(tabFromHash?.key || "1");
    }
  }, [filterSearch, selectedTab, location.hash]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortAlias: any = { ascend: "asc", descend: "desc" };
    setfilterSearch({
      ...filterSearch,
      sort_direction: sortAlias[sorter.order] ?? "",
      sort_by: sortRenameUserId(sorter.field) ?? "",
      page: pagination.current,
      ...filters,
    });
  };

  const handleVisibleChange = () => setIspopOverVisible(!ispopOverVisible);

  const handleMultiSelection = (selectedCustomerIds: any[]) =>
    setSelectedCustomerIds(selectedCustomerIds);

  const handleRowClick = () => {};

  return (
    <div className="account-manager__container">
      <div className="account-manager__header">
        <PageHeader title="Account Manager" />
      </div>
      <Tabs activeKey={selectedTab} onChange={tabChangeHandler}>
        {accountManagerTabs.map(({ title, key, hash }) => (
          <TabPane active={selectedTab === key} tab={title} key={key} />
        ))}
      </Tabs>
      <div className="account-manager__table">
        <Row justify="space-between">
          <Col>
            <SearchBar
              placeholder="Search by customer name, id"
              filters={false}
              onChange={(value) =>
                setfilterSearch({ ...filterSearch, search: value })
              }
            />
            <Popover
              content={
                <FilterForm
                  customers={customers}
                  handleVisibleChange={handleVisibleChange}
                  filterSearch={filterSearch}
                  setfilterSearch={setfilterSearch}
                />
              }
              title="Filters"
              trigger="click"
              className="popOver"
              visible={ispopOverVisible}
              onVisibleChange={handleVisibleChange}
            >
              <Button className="filter-btn" onClick={handleVisibleChange}>
                Filter
              </Button>
            </Popover>
          </Col>
          <Col>
            <AllocateForm
              selectedCustomerIds={selectedCustomerIds}
              setSelectedCustomerIds={setSelectedCustomerIds}
              onSubmit={fetchNewData}
            />
          </Col>
        </Row>

        <TableComponent
          className="account-manager__list-table"
          onRow={handleRowClick}
          loading={loading}
          columns={column}
          dataSource={customers?.data}
          rowKey="userId"
          pagination={{
            pageSize: customers?.perPage || 0,
            total: customers?.total || 0,
            current: customers?.currentPage || 0,
            showTotal: getPaginationFooter,
          }}
          onChange={handleTableChange}
          rowSelection={{
            selectedRowKeys: selectedCustomerIds,
            onChange: handleMultiSelection,
          }}
        />
      </div>
    </div>
  );
};

export default AuthContainer(AccountManagerList);
