import { alias, list, primitive, serializable } from "serializr";

export class Share {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("phone", primitive()))
  mobileNumber?: number;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("status_url", primitive()))
  dynamicUrl?: string;
}

export class ShareBreakdown {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("breakdown", list(list(primitive()))))
  breakdown?: string[][];

  @serializable(alias("amount_needed", primitive()))
  amountNeeded?: number;
}

export class ShareUnit {
  @serializable(alias("unit", primitive()))
  unit?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;
}

export class SingleShareCalculation {
  @serializable(alias("current_price", primitive()))
  currentPrice?: number;

  @serializable(alias("buy_service_charge_percent", primitive()))
  buyPercent?: number;

  @serializable(alias("sell_service_charge_percent", primitive()))
  sellPercent?: number;
}
