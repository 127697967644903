import React, { FC, useEffect, useRef, useState } from "react";
import { Customer } from "../../../../models/Customer/customer.model";
import CustomerService from "../../../../services/CustomerService/customer.service";
import TableComponent from "../../../../shared/components/TableComponent";

import {
  allCustomersColumns,
  incompleteCustomersColumns,
  pendingCustomersColumns,
} from "./customerTable";
import {
  Tabs,
  Button,
  Select,
  Row,
  Col,
  Popover,
  Form,
  Tag,
  Table,
} from "antd";
import PageHeader from "../../../../shared/components/PageHeader";
import "./customerList.scss";
import { Formik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import SearchBar from "../../../../shared/components/SearchBar";
import { CustomerTypes } from "../../../../enums/customerTypes";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import DropdownField from "../../../../shared/components/DropdownField/index";
import { ColumnsType } from "antd/lib/table";
import { getPaginationFooter } from "../../../../shared/utils/getPaginationFooter";

interface CustomerListProps extends AuthState { }

const customerTabs = [
  {
    key: "1",
    title: "All",
    hash: "all-customers"
  },
  {
    key: "2",
    title: "Pending",
    hash: "pending-customers"
  },
  {
    key: "3",
    title: "Incomplete",
    hash: "incomplete-customers"
  },
]

const CustomersList: FC<CustomerListProps> = (props) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const location = useLocation();
  const customerId = history.location.pathname.split("/").pop();

  const { user } = props;
  const [column, setColumn] = useState<ColumnsType<any>>(allCustomersColumns);
  /*  */
  const [selectedTab, setSelectedTab] = useState<string>();
  const [ispopOverVisible, setIspopOverVisible] = useState(false);
  const { Option } = Select;
  const ref = useRef<any>(null);

  const [filterSearch, setfilterSearch] = useState({
    page: "",
    search: "",
    sort: "",
    sort_by: "",
    sort_direction: "",
    filters: {},
  });

  const [filterData, setFilterData] = useState<any>({});

  const {
    fetchCustomers,
    customers,
    loading,
    fetchPendingCustomers,
    fetchIncompleteCustomers,
  } = CustomerService();

  useEffect(() => {
    const debouceTimeout = setTimeout(() => {
      if (selectedTab === "1") {
        fetchCustomers(filterSearch);
      } else if (selectedTab === "2") {
        fetchPendingCustomers(filterSearch);
      }
    }, 800);

    return () => {
      clearTimeout(debouceTimeout);
    };
  }, [filterSearch.search]);

  const tabChangeHandler = (key: string) => {
    const hash = customerTabs.find(tab => tab.key === key)?.hash
    setSelectedTab(key);
    history.push(AppRoutes.CUSTOMERS + `#${hash}`)
    setfilterSearch({
      filters: {},
      page: "",
      search: "",
      sort: "",
      sort_by: "",
      sort_direction: "",
    });
  };

  useEffect(() => {
    const tabFromHash = customerTabs.find(tab => tab.hash === location.hash?.replace("#", "")) || customerTabs[0]
    if (selectedTab && selectedTab === tabFromHash?.key) {
      if (selectedTab === "1") {
        setColumn(allCustomersColumns);
        fetchCustomers(filterSearch);
      } else if (selectedTab === "2") {
        setColumn(pendingCustomersColumns);
        fetchPendingCustomers(filterSearch);
      } else if (selectedTab === "3") {
        setColumn(incompleteCustomersColumns);
        fetchIncompleteCustomers(filterSearch);
      }
    } else {
      setSelectedTab(tabFromHash?.key || "1")
    }
  }, [filterSearch, selectedTab, location.hash]);

  /* navigating to user profile */
  const handleRowClick = (customer: Customer) => {
    if (customer?.userId ) {
      if (selectedTab === "2") {
        history.push(
          AppRoutes.CUSTOMERS_PROFILE.replace(
            ":customerId",
            customer.userId?.toString()
          ),
          {
            enableVerification: true,
          }
        );
      } else {
        history.push(
          AppRoutes.CUSTOMERS_PROFILE.replace(
            ":customerId",
            customer.userId?.toString()
          )
        );
      }
    }
  };

  const sortRenameUserId = (uId: string) => {
    switch (uId) {
      case "uId":
        return "user_id";
      case "name":
        return "name";
      case "role":
        return "role";
      case "joinedDate":
        return "joined_date";
      case "email":
        return "email";
      case "subscription":
        return "subscription";
      case "lastupdate":
        return "last_updated_on";
      // case "submittedDate":
      //   return "date_submitted";
    }
  };
  interface handleProps {
    pagination: {
      current: string;
    };
    sorter: {
      order: string;
      field: string;
    };
    filters: {};
  }
  /* pagination: any, filters: any, sorter: any) */
  // const handleTableChange = (pagination: any, sorter: any, filters: any) => {
  //   const { sorter, filters, pagination } = props;
  //   console.log("this is my sorted", pagination);
  //   const sortAlias: any = { ascend: "asc", descend: "desc" };
  //   setfilterSearch({
  //     ...filterSearch,
  //     sort_direction: sortAlias[sorter.order] ?? "",
  //     sort_by: sortRenameUserId(sorter.field) ?? "",
  //     page: pagination.current,
  //     ...filters,
  //   });
  // };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortAlias: any = { ascend: "asc", descend: "desc" };
    setfilterSearch({
      ...filterSearch,
      sort_direction: sortAlias[sorter.order] ?? "",
      sort_by: sortRenameUserId(sorter.field) ?? "",
      page: pagination.current,
      ...filters,
    });
    /* fetchCustomers(filterSearch); */
  };

  const handleSubmit = async (values: any) => {
    // console.log("this is my values", values);
  };
  const initialValues = {
    /*  amount: amount, */
  };
  const formikProps = {
    initialValues,

    onSubmit: handleSubmit,
  };

  const reset = () => { };

  const handleVisibleChange = () => {
    ispopOverVisible ? setIspopOverVisible(false) : setIspopOverVisible(true);
  };

  const hide = () => {
    setIspopOverVisible(false);
  };

  const content = (
    <div className="filterPopup">
      <Formik innerRef={ref} {...formikProps}>
        {({ setFieldValue, values }) => {
          return (
            <Form className="fiter-form">
              <div className="styled-modal-btn">
                <Row justify="end">
                  <Col span={14}>
                    {" "}
                    <h3>Filters</h3>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      className="customerResetbtn"
                      htmlType="reset"
                      size="middle"
                      onClick={() => {
                        setfilterSearch({} as any);
                        setFilterData({});
                        handleVisibleChange();
                      }}

                    /*    onClick={() => handleSubmit(values)} */
                    >
                      {`Reset`}
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      className="customerSubmitbtn"
                      onClick={() => {
                        setfilterSearch({
                          ...filterSearch,
                          filters: filterData,
                        });
                        ref?.current?.submitForm();
                        handleVisibleChange();
                      }}
                    >
                      {`Apply`}
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                {Object.keys(customers?.availableFilters ?? {}).map((key) => {
                  return (
                    <Col span={12}>
                      <DropdownField
                        value={filterData[key]}
                        name={"filters." + key}
                        title={key}
                        mode={
                          customers?.availableFilters?.[key].is_multi_select
                            ? "multiple"
                            : undefined
                        }
                        placeHolder="Select"
                        showArrow
                        options={customers?.availableFilters?.[
                          key
                        ]?.values?.map((value) => ({
                          value,
                          label: value,
                        }))}
                        onChange={(value) => {
                          setFilterData({
                            ...filterData,
                            [key]: value,
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <div className="customer__container">
      <div className="customer__header">
        <PageHeader title="Customers" />
      </div>
      <Tabs activeKey={selectedTab} onChange={tabChangeHandler}>
        {customerTabs.map(({ title, key, hash }) => <TabPane active={selectedTab === key} tab={title} key={key} />)}
      </Tabs>
      <div className="customer__table">
        <SearchBar
          placeholder="Search by customer name, id"
          filters={false}
          onChange={(value) =>
            setfilterSearch({ ...filterSearch, search: value })
          }
        />

        <Popover
          content={content}
          title="Filters"
          trigger="click"
          className="popOver"
          visible={ispopOverVisible}
          onVisibleChange={handleVisibleChange}
        >
          <Button className="filterBtn" onClick={handleVisibleChange}>
            Filter
          </Button>
        </Popover>

        <TableComponent
          className="customer-list-table"
          onRow={handleRowClick}
          loading={loading}
          columns={column}
          dataSource={customers?.data}
          rowKey="userId"
          pagination={{
            pageSize: customers?.perPage || 0,
            total: customers?.total || 0,
            current: customers?.currentPage || 0,
            showTotal: getPaginationFooter
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default AuthContainer(CustomersList);
