import {
  alias,
  object,
  primitive,
  serializable,
  SKIP,
  custom,
} from "serializr";

interface PaymentDetails {
  [x: string]: string;
}

export class Wallet {
  @serializable(alias("dynamic", primitive()))
  dynamicBalance?: number;

  @serializable(alias("static", primitive()))
  staticBalance?: number;

  @serializable(alias("holdings", primitive()))
  holdings?: number;

  @serializable(alias("sellable_holdings", primitive()))
  sellableHoldings?: number;

  @serializable(alias("current_share_value", primitive()))
  currentShareValue?: number;

  @serializable(alias("value_of_holdings", primitive()))
  valueOfHoldings?: number;

  @serializable(alias("interest_percent", primitive()))
  interestPercent?: number;

  @serializable(alias("interest_amount", primitive()))
  interestAmount?: number;

  @serializable(alias("capital_appreciation", primitive()))
  capitalAppreciation?: number;

  @serializable(alias("dividends_earned", primitive()))
  dividendsEarned?: number;

  @serializable(alias("total_investment", primitive()))
  totalInvestment?: number;

  @serializable(alias("returns_percent", primitive()))
  returnsPercent?: number;
}

class ExportHistoryDetails {
  @serializable(alias("total", primitive()))
  total?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("unit_price", primitive()))
  unitPrice?: number;

  @serializable(alias("share_quantity", primitive()))
  shareQuantity?: number;
}

class ExportPaymentDetails {
  @serializable(alias("Transaction Id ", primitive()))
  transactionId?: number;

  @serializable(alias("Mode of Payment", primitive()))
  modeofPayment?: string;

  @serializable(alias("Phone", primitive()))
  phone?: string;
}

export class WalletHistory {
  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("debit_acc", primitive()))
  debitAccount?: string;

  @serializable(alias("credit_acc", primitive()))
  creditAccount?: string;

  @serializable(alias("transaction_date", primitive()))
  transactionDate?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("order_id", primitive()))
  orderId?: number;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("for_dynamic_wallet", primitive()))
  forDynamicWallet?: boolean;

  @serializable(alias("details", object(ExportHistoryDetails)))
  details?: ExportHistoryDetails;

  @serializable(
    alias(
      "payment_details",
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
    )
  )
  paymentDetails?: PaymentDetails;
}
