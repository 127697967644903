import { Button, Collapse, Form, Modal } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import "./kycDocumentModal.scss"
import AcceptIcon from "./../../../assets/icons/multi color icons - svg/accept.svg"
import RejectIcon from "./../../../assets/icons/multi color icons - svg/reject.svg";
import { Customer, CustomerKycDetails } from "../../../models/Customer/customer.model";
import AlertModal from "../AlertModal";
import { Formik } from "formik";
import InputField from "../InputField";

interface KycDocumentModalProps {
    visible: boolean
    hideStatus: boolean
    url: string
    name: string
    type: string
    title: string
    customer: Customer
    kycDetails: CustomerKycDetails
    closeHandler: () => void
    status: string
    updateVerificationStatus: Function
}

const KycDocumentModal = (props: KycDocumentModalProps) => {

    const {
        visible,
        hideStatus,
        url,
        name,
        type,
        closeHandler,
        customer,
        status,
        title,
        updateVerificationStatus,
        kycDetails,
    } = props

    const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false)
    const [selectedField, setSelectedField] = useState<string>()
    const formikRef = useRef(null)

    useEffect(() => {
        setHeaderOpen(false)
    }, [visible])


    const headerDetails = [
        {
            title: 'Name',
            value: customer?.name
        },
        {
            title: 'ID Number',
            value: customer?.kycBasicInfo?.kenyanNationalNumber || customer?.kycBasicInfo?.KRAPinNumber
        },
        {
            title: 'Gender',
            value: customer?.kycBasicInfo?.gender
        },
        {
            title: 'DOB',
            value: customer?.kycBasicInfo?.dateOfBirth
        },
        {
            title: 'District of Birth',
            value: customer?.kycBasicInfo?.districtOfBirth
        },
        {
            title: 'Place of issue',
            value: customer?.kycBasicInfo?.placeOfIssue
        },
        {
            title: 'Date of issue',
            value: customer?.kycBasicInfo?.dateOfIssue
        },
        {
            title: 'District',
            value: customer?.kycBasicInfo?.district
        },
        {
            title: 'Location',
            value: customer?.kycBasicInfo?.location
        },
        {
            title: 'Sub-location',
            value: customer?.kycBasicInfo?.subLocation
        },
    ]

    const rejectionHandler = (field: string) => {
        setSelectedField(field)
        setAlertModalVisible(true)
    }

    const rejectionSubmitHandler = () => {
        if (!(formikRef.current as any)?.values?.reason) { return }
        setAlertModalVisible(false);
        setTimeout(() => {
            updateVerificationStatus(selectedField, (formikRef.current as any).values.reason)
            setSelectedField('');
            (formikRef.current as any).resetForm()
        }, 500)
    }

    const closeRejectionModal = () => {
        setAlertModalVisible(false);
        (formikRef.current as any).resetForm()
    }

    const updateVerificationHandler = (field: string, value: false | string) => {
        updateVerificationStatus(field, value)
        closeHandler()
    }

    const [isHeaderOpen, setHeaderOpen] = useState<boolean>(false)

    return <Modal className="kyc-document-modal" visible={visible} onCancel={closeHandler}>
        <div className="kyc-document-modal__header">
            <p className="kyc-document-modal--title">{name}</p>
            {!hideStatus ?
                null
                : status === 'pending'
                    ? <Fragment>
                        <img className="kyc-document-modal--accept"
                            onClick={() => updateVerificationHandler(title, false)}
                            src={AcceptIcon} alt="accept" />
                        <img className="kyc-document-modal--reject"
                            onClick={() => rejectionHandler(title)}
                            src={RejectIcon} alt="reject" />
                    </Fragment>
                    : <p className={`kyc--status ${status}`}>{status}</p>
            }
            {type !== 'video' && <p className="kyc-document-modal--toggle" onClick={() => {
                setHeaderOpen(prev => !prev)
            }}>Details</p>}
            <div className={`kyc-document-modal__header--accordion ${isHeaderOpen ? 'panel-open' : 'panel-closed'}`} >
                {
                    headerDetails.map(detail => {
                        const list = customer?.kycBasicInfo?.isFromIprs || detail?.title === 'ID Number'
                            ? <div key={detail?.title} className="accordion-content">
                                <p className="accordion-content--title">{detail?.title}</p>
                                <p className="accordion-content--value">{detail?.value}</p>
                            </div>
                            : null
                        return list
                    })
                }

            </div>
        </div>
        <div className="kyc-document__body">
            {type === 'pdf'
                ?
                <div className="kyc-document--pdf">
                    <iframe src={url + '#toolbar=0&navpanes=0'} frameBorder="0"></iframe>
                </div>
                : type === 'video'
                    ? <div className="kyc-document--video">
                        <p className="kyc-document--video-code">KYC Code: <span>{kycDetails?.kycVideoProof?.verificationCode}</span></p>
                        <video src={url} playsInline controls />
                    </div>
                    : ""
            }
        </div>
        <AlertModal
            className="reject-modal"
            title={`Reject ${selectedField?.replaceAll("_", ' ')}`}
            visible={alertModalVisible}
            onOk={() => rejectionSubmitHandler()}
            onCancel={() => closeRejectionModal()}>
            <p>Are you sure to reject the document? This action cannot be undone</p>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    reason: ''
                }}
                onSubmit={(values) => {
                    // console.log(values);
                }}
            >
                <Form>
                    <InputField
                        title="Rejection Reason"
                        type="text"
                        name="reason"
                        placeholder="Rejection Reason"
                    />
                </Form>
            </Formik>
        </AlertModal>
    </Modal>
}

export default KycDocumentModal