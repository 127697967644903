import React, { FC, useEffect, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation";
import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useHistory, withRouter } from "react-router-dom";
import OnBoarding from "../../../shared/components/Layout/OnBoarding";
// import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/login.png";
// import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import LockIcon from "../../../assets/icons/icon.svg";
import "./login.scss";
// import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import OTPForm from "./OTPForm";
// import KYCService from "../../../services/KYCService/kyc.service";

interface User {
    email: string;
    password: string;
}

const initialValue = {
    email: "",
    password: "",
};

interface LoginFormProps { }

const LoginForm: FC<LoginFormProps> = (props) => {

    const { user, error, loading, triggerOtp, verifyOtp, requestResetPasswordLink } = AuthService();

    const [otpModalVisibility, setOtpModalVisibility] = useState<boolean>(false)
    const [logingUser, setLogingUser] = useState<User>()
    const [passwordExpired, setPasswordExpired] = useState<boolean>(false)
    // const { getStepperStatus, loading: stepLoading } = KYCService();

    const history = useHistory();


    const onSubmit = async (user: User) => {
        try {
            setLogingUser(user)
            await triggerOtp(user);
            setOtpModalVisibility(true)
            // prompt('Otp')setLogingUser
            // history.push(AppRoutes.HOME);
        } catch (error) {
            const passwordExpired = { ...error as any }['response']["data"]["password_expiry"]
            passwordExpired &&
                await requestResetPasswordLink(user?.email, true) &&
                setPasswordExpired(true)
        }
    };

    const verifyOtpHandler = async (values: FormikValues) => {
        try {
            const response = await verifyOtp({ ...values, ...logingUser });
            history.push(AppRoutes.CUSTOMERS);
        } catch (error) {

        }
    }


    return (
        <div className="login">
            <OnBoarding>
                <Formik
                    initialValues={initialValue}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <h1 className="text-primary text-center">
                            Login to admin account
                        </h1>
                        <InputField
                            title="Email"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                        />
                        <InputField
                            title="Password"
                            className={passwordExpired ? "mb-0" : ""}
                            type="password"
                            name="password"
                            placeholder="Enter password"
                        />
                        {passwordExpired && <span className="input__error">Your password is expired. Password reset link has been sent to your mail id</span>}
                        <div className="submit-btn mt-5">
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="middle"
                                loading={loading}
                            >
                                {!loading && <img src={LockIcon} alt="" />}{" "}
                                &nbsp; Login
                            </Button>
                        </div>
                        <TermsAndPolicy />
                    </Form>
                </Formik>
            </OnBoarding>
            <OTPForm
                loading={loading}
                otpModalVisibility={otpModalVisibility}
                otpSubmissionHandler={verifyOtpHandler}
            ></OTPForm>
        </div>
    );
};

export default LoginForm;
