import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Formik, Form, FormikHelpers, FormikConfig } from "formik";
import DropdownField from "../../../../../shared/components/DropdownField";
import { Button, Tooltip } from "antd";
import "./allocateForm.scss";
import { AllocateManager } from "../../../../../models/AccountManager/allocateManager.model";
import AccountManagerService from "../../../../../services/AccountManagerService/accountManager.service";

interface AllocateFormProps {
  selectedCustomerIds: number[];
  setSelectedCustomerIds: Dispatch<SetStateAction<number[]>>;
  onSubmit?: () => void;
}

const allocateManagerErrorMessage = {
  Customer: "Please select at least one customer",
  Manager: "Please select a manager",
  CustomerManager: "Please select at least one customer and a manager",
};

const AllocateForm: FC<AllocateFormProps> = (props) => {
  const { selectedCustomerIds, setSelectedCustomerIds, onSubmit } = props;

  const {
    accountManagers,
    fetchManagers,
    assignAccountManager,
    loading,
    buttonLoading,
  } = AccountManagerService();

  useEffect(() => {
    fetchManagers();
  }, []);

  const getTooltipText = (isAllocatingUserId: boolean) => {
    switch (true) {
      case selectedCustomerIds.length <= 0 && !isAllocatingUserId:
        return allocateManagerErrorMessage.CustomerManager;
      case selectedCustomerIds.length <= 0:
        return allocateManagerErrorMessage.Customer;
      case !isAllocatingUserId:
        return allocateManagerErrorMessage.Manager;
      default:
        return "";
    }
  };

  const handleAllocateManager = async (
    values: AllocateManager,
    formikHelpers: FormikHelpers<AllocateManager>
  ) => {
    if (await assignAccountManager({...values, userIds: selectedCustomerIds})) {
      formikHelpers.resetForm();
      setSelectedCustomerIds([]);
      onSubmit && onSubmit();
    }
  };

  const formikProps: FormikConfig<AllocateManager> = {
    initialValues: new AllocateManager(),
    onSubmit: handleAllocateManager,
  };

  return (
    <div className="allocate-manager">
      <Formik {...formikProps}>
        {({ values }) => (
          <Form className="allocate-manager__form">
            <DropdownField
              name="allocatingUserId"
              placeHolder="Select Manager"
              allowClear
              showSearch
              disabled={loading}
              loading={buttonLoading}
              optionLabelProp="label"
              value={values.allocatingUserId}
              options={
                accountManagers?.map((manager) => ({
                  label: manager.name,
                  value: manager.id,
                })) || []
              }
            />
            <Tooltip title={getTooltipText(!!values.allocatingUserId)}>
              <Button
                className="allocate-manager__form__allocate-btn"
                htmlType="submit"
                disabled={
                  !(selectedCustomerIds.length > 0) || !values.allocatingUserId
                }
                loading={loading}
              >
                Allocate Manager
              </Button>
            </Tooltip>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AllocateForm;
