import {
  alias,
  primitive,
  serializable,
} from "serializr";


export class Withdraw {

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("created_for", primitive()))
  userId?: string;

  @serializable(alias("amount", primitive()))
  amountRequested?: string;

  @serializable(alias("account_number", primitive()))
  accountNumber?: string;

  @serializable(alias("comment", primitive()))
  comment?: string;

  @serializable(alias("comments", primitive()))
  comments?: string;

  @serializable(alias("customer_id", primitive()))
  customerId?: string;

  @serializable(alias("trans_id", primitive()))
  transId?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("transaction_reference_number", primitive()))
  transactionId?: string;

  @serializable(alias("status_id", primitive()))
  statusId?: 0 | -1 | 1;

  @serializable(alias("status", primitive()))
  status?: 0 | -1 | 1;

  @serializable(alias("payment_method_id", primitive()))
  paymentMode?: string;

  @serializable(alias("phone", primitive()))
  mobileNumber?: string;

  @serializable(alias("dial_code", primitive()))
  dialCode?: string = "+254";

  @serializable(alias("withdraw_to", primitive()))
  withdrawTo?: "mpesa" | "bank";

  @serializable(alias("email_otp", primitive()))
  emailOtp?: string;

  @serializable(alias("phone_otp", primitive()))
  phoneOtp?: string;
}
