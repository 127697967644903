import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import TableComponent from "../../../shared/components/TableComponent";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import PaymentService from "../../../services/Payments/payment.service";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import CloseIcon from "../../../assets/icons/close-btn.svg";
import "./payments.scss";
import { Button, Divider, Modal, Space } from "antd";
import { Payment } from "../../../models/Payments/payment.model";
import { fetchPaymentColumns } from "./fetchPaymentColumns";
import SearchBar from "../../../shared/components/SearchBar";

const Payments = () => {
  const { pagination, payments, getPaymentDetails, loading, cancelPayment } =
    PaymentService();

  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    getPaymentDetails({ search, page: 1 });
  }, [search]);

  const handlePaymentClick = (data: Payment | null) => {
    setSelectedPayment(data);
  };

  const { columns } = fetchPaymentColumns(setSelectedPayment);

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null>,
    sort?: SorterResult<any> | any[]
  ) => {
    const order = (sort as any)?.order;
    const sortDirections: Record<string, string> = {
      ascend: "asc",
      descend: "desc",
    };
    if (order)
      getPaymentDetails({
        page: pagination?.current || 1,
        sort_dir: sortDirections[order],
        sort_by: (sort as any)?.columnKey,
        search: search
      });
    else getPaymentDetails({ page: pagination?.current || 1, search: search });
  };

  const handleCancelPayment = async () => {
    selectedPayment?.id && (await cancelPayment(selectedPayment.id));
    setSelectedPayment(null);
    await getPaymentDetails();
  };

  const CANCEL_PAYMENT_CONFIRMATION =
    "Are you sure to cancel this payment? This action cannot be undone.";

  return (
    <div className="payments">
      <div className="payments__header">
        <PageHeader title="Payments" />
      </div>

      <div className="payments__table">
        <SearchBar
          placeholder="Search by customer name, id"
          filters={false}
          onChange={(search) => setSearch(search)}
        />

        <TableComponent
          loading={loading}
          columns={columns}
          rowKey={"id"}
          dataSource={payments}
          onChange={handleTableChange}
          pagination={{
            pageSize: pagination?.perPage || 0,
            total: pagination?.total || 0,
            current: pagination?.currentPage || 0,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      </div>

      <Modal
        width={560}
        visible={selectedPayment !== null}
        footer={null}
        closable={false}
        className="payments__reject-confirmation"
      >
        <div className="payments__reject-confirmation--container">
          <div className="payments__reject-confirmation--container--header">
            <div className="payments__reject-confirmation--container--header--title">
              Cancel Payment
            </div>
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => handlePaymentClick(null)}
            />
          </div>
          <div className="payments__reject-confirmation--container--warning">
            <Space size={8}>
              <img src={WarningIcon} alt="warning-icon" />
              <div>{CANCEL_PAYMENT_CONFIRMATION}</div>
            </Space>
          </div>
          <Divider />
          <div className="payments__reject-confirmation--container--data">
            <div className="payments__reject-confirmation--container--data--key">
              Customer
            </div>
            <div className="payments__reject-confirmation--container--data--value payments__reject-confirmation--container--data--name">
              {selectedPayment?.customerName}
            </div>
          </div>
          <Divider />
          <div className="payments__reject-confirmation--container--data">
            <div className="payments__reject-confirmation--container--data--key">
              Date requested
            </div>
            <div className="payments__reject-confirmation--container--data--value">
              {selectedPayment?.date}
            </div>
          </div>
          <Divider />
          <div className="payments__reject-confirmation--container--data">
            <div className="payments__reject-confirmation--container--data--key">
              Payment Type
            </div>
            <div className="payments__reject-confirmation--container--data--value">
              {selectedPayment?.paymentType}
            </div>
          </div>
          <Divider />
          <div className="payments__reject-confirmation--container--data">
            <div className="payments__reject-confirmation--container--data--key">
              Amount
            </div>
            <div className="payments__reject-confirmation--container--data--value">
              {selectedPayment?.amount}
            </div>
          </div>
          <Divider />
          <div className="payments__reject-confirmation--container--submit-sec">
            <Button
              className="payments__reject-confirmation--container--submit-sec--cancel-btn"
              onClick={() => handlePaymentClick(null)}
            >
              Cancel
            </Button>
            <Button
              className="payments__reject-confirmation--container--submit-sec--reject-btn"
              onClick={handleCancelPayment}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Payments;
