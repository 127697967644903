import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import OnBoarding from "../../../shared/components/Layout/OnBoarding";
import LocalStorage from "../../../shared/LocalStorage";
import AuthRouter from "../authRouters";


const AuthWrapper = () => {
    const history = useHistory()

    useEffect(() => {
        if (LocalStorage.getItem('vuka-token')) {
            history.push(AppRoutes.APP)
        }
    }, [])

    return (
        <AuthRouter />
    )
}

export default AuthWrapper;
