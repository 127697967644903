import React, { FC, useEffect, useRef, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import SearchBar from "../../../shared/components/SearchBar";
import TableComponent from "../../../shared/components/TableComponent";
import "./withdrawReqs.scss"
import ApproveIcon from "./../../../assets/icons/multi color icons - svg/accept.svg"
import RejectIcon from "./../../../assets/icons/multi color icons - svg/reject.svg"
import WalletService from "../../../services/WalletService/wallet.service";
import { Withdraw } from "../../../models/Withdraw/withdraw.model";
import { WithdrawalModes, WithdrawalModesObject, WithdrawalStatuses } from "../../../enums/withdrawalModes";
import { Col, Form, Modal, Row, Tooltip } from "antd";
import { title } from "process";
import AlertModal from "../../../shared/components/AlertModal";
import UIModal from "../../../shared/components/UIModal";
import { Formik, FormikHelpers, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { withdrawalApprovalValidation, withdrawalRejectionValidation } from "./withdrawApproval.validation";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { numberConvention } from "../../../shared/utils/numberConvention";
import moment from "moment";

interface WithdrawReqsProps {
}

const WithdrawReqs: FC<WithdrawReqsProps> = (props) => {

    const {
        submitting,
        walletLoading,
        withdrawList,
        getWithdrawReqs,
        withdrawPagination,
        updateWithdrawReq,
    } = WalletService()

    const [selectedWithdrawReq, setSelectedWithdrawReq] = useState<Withdraw>()

    const formikRef = useRef<FormikProps<Withdraw>>(null)
    const formikRejectionRef = useRef<FormikProps<Withdraw>>(null)

    useEffect(() => {
        getWithdrawReqs()
    }, [])

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: true,
            width: 200,
            render: (text: string) => text && moment(text).format("DD, MMM YYYY hh:mm A")
        },
        {
            title: "Customer",
            dataIndex: "customerName",
            key: "customer_name",
            sorter: true,
            width: 200,
            ellipsis: true,
            render: (text: string) => <span className="text-capitalize">{text?.toLowerCase()}</span>
        },
        {
            title: "Customer ID",
            dataIndex: "customerId",
            key: "customer_id",
            sorter: true,
            width: 100,
            ellipsis: true,
        },
        {
            title: "Amount",
            dataIndex: "amountRequested",
            key: "amount",
            sorter: true,
            width: 200,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: string) => numberConvention(text || 0)
        },
        {
            title: "Withdraw To",
            dataIndex: "withdrawTo",
            key: "withdraw_to",
            sorter: true,
            width: 100,
            render: (text: string) => <span>{text && WithdrawalModesObject[text]}</span>
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            key: "account_number",
            sorter: true,
            width: 200,
        },
        {
            title: "Trans ID",
            dataIndex: "transId",
            key: "transId",
            width: 100,
            ellipsis: true,
            render: (text: string) => <Tooltip overlayClassName="table-tooltip" placement="bottomLeft" title={text || ''}>
                <span> {text || "-"}</span>
            </Tooltip>
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            width: 150,
            ellipsis: true,
            render: (text: string) => <Tooltip overlayClassName="table-tooltip" placement="bottomLeft" title={text || ''}>
                <span> {text || "-"}</span>
            </Tooltip>
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 100,
            sorter: true,
            render: (text: string, record: Withdraw) => text.toLowerCase() !== "pending"
                ? <span className={`kyc--status ${text}`}>{text}</span>
                : <div className="withdraw-reqs__table--actions">
                    <img src={ApproveIcon} alt="approve" onClick={() => selectionHandler(record, 1)} />
                    <img src={RejectIcon} alt="reject" onClick={() => selectionHandler(record, -1)} />
                </div>
        },
    ]



    const selectionHandler = (request: Withdraw, status: 0 | -1 | 1) => {
        setSelectedWithdrawReq({
            ...request,
            status
        })
    }

    const closeModal = async (withdraw?: Withdraw) => {
        if (withdraw && selectedWithdrawReq?.id) {
            const response = await updateWithdrawReq(selectedWithdrawReq?.id, withdraw)
            if (!response) return
            getWithdrawReqs()
            setSelectedWithdrawReq(undefined)
        } else {
            setSelectedWithdrawReq(undefined)
        }
    }

    const rowClassGenerator = (request: Withdraw) =>
        request.status === 0 ? "text-bold" : ""


    const handleWithdrawTableChange = (
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sort?: SorterResult<any> | any[]) => {
        const order = (sort as any)?.order
        const sortDirections: Record<string, string> = {
            'ascend': "asc",
            'descend': "desc",
        }
        if (order)
            getWithdrawReqs({ page: pagination?.current || 1, sort_dir: sortDirections[order], sort_by: (sort as any)?.columnKey })
        else
            getWithdrawReqs({ page: pagination?.current || 1 })
    }
    return (
        <div className="withdraw-reqs">
            <div className="withdraw-reqs__header">
                <PageHeader title="Withdraw Request" />
            </div>
            <div className="withdraw-reqs__table">
                <SearchBar
                    placeholder="Search by customer name, id"
                    filters={false}
                    onChange={(search) => getWithdrawReqs({ search, page: 1 })
                    }
                />
                <TableComponent
                    rowClassName={rowClassGenerator}
                    loading={walletLoading}
                    columns={columns}
                    rowKey={"id"}
                    dataSource={withdrawList}
                    onChange={handleWithdrawTableChange}
                    pagination={{
                        pageSize: withdrawPagination?.perPage || 0,
                        total: withdrawPagination?.total || 0,
                        current: withdrawPagination?.currentPage || 0,
                        showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                    }}
                />
            </div>
            {selectedWithdrawReq &&
                selectedWithdrawReq.status === -1 &&
                <AlertModal
                    className="reject-modal"
                    title={`Reject Withdraw Request`}
                    visible={!!selectedWithdrawReq?.id}
                    okText="Reject"
                    okButtonProps={{ loading: submitting }}
                    onOk={() => formikRejectionRef.current?.submitForm()}
                    onCancel={() => closeModal()} >
                    <p className="withdraw-req__content">
                        <i className="icon-alert" />
                        <span>Are you sure to reject the request? This action cannot be undone</span>
                        <Formik
                            initialValues={new Withdraw()}
                            innerRef={formikRejectionRef}
                            validationSchema={withdrawalRejectionValidation}
                            onSubmit={(values) => closeModal({ ...selectedWithdrawReq, ...values, statusId: -1 })}>
                            {({ isValid, dirty, errors }) => {
                                return <Form>
                                    <Row justify="center" className="mt-5">
                                        <Col span={24}>
                                            <InputField type={"textarea"}
                                                name={"comments"}
                                                title="Comment"
                                                placeholder="Comment" />
                                        </Col>
                                    </Row>
                                </Form>
                            }}
                        </Formik>
                    </p>
                </AlertModal>}
            {selectedWithdrawReq &&
                selectedWithdrawReq.status === 1 &&
                <UIModal
                    title={`Approve Withdraw Request`}
                    visible={!!selectedWithdrawReq?.id}
                    okText="Confirm"
                    footer={false}
                    centered={true}
                    onConfirm={() => formikRef.current?.submitForm() || {}}
                    onCancel={() => closeModal()} >
                    <Formik
                        initialValues={new Withdraw()}
                        innerRef={formikRef}
                        validationSchema={withdrawalApprovalValidation}
                        onSubmit={(values) => closeModal({ ...selectedWithdrawReq, ...values, statusId: 1 })}>
                        {({ isValid, dirty, errors }) => {
                            return <Form>
                                <Row justify="center">
                                    <Col span={24}>
                                        <InputField
                                            type={"text"}
                                            name={"transactionId"}
                                            title="Transaction ID"
                                            placeholder="Transaction ID"
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <InputField type={"textarea"}
                                            name={"comments"}
                                            title="Comment"
                                            placeholder="Comment" />
                                    </Col>
                                    <Col span={18} className="mt-5">
                                        <ButtonComponent
                                            htmlType="submit"
                                            type="primary"
                                            loading={submitting}
                                            onClick={() => formikRef.current?.submitForm() || {}}
                                            disabled={!isValid || !dirty}
                                        >
                                            Confirm
                                        </ButtonComponent>
                                    </Col>
                                </Row>
                            </Form>
                        }}
                    </Formik>
                </UIModal>}
        </div>
    )
}

export default WithdrawReqs;