import { serialize } from 'serializr';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { useState } from 'react';
import axiosInstance from '../../interceptor/axiosInstance';
import { ReportModel } from './../../models/ReportModel/report.model';
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { objectToIndexedQueryString } from '../../shared/utils/objectToQueryHelper';

const ReportService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const generateParams = (reportData: ReportModel) => {
        const formattedRequest = serialize(ReportModel, reportData)
        const { start_date, end_date } = formattedRequest
        delete formattedRequest.start_date
        delete formattedRequest.end_date
        const params = {
            start_date,
            end_date,
            filters: formattedRequest
        }
        !params.start_date && delete params.start_date
        !params.end_date && delete params.end_date
        return params
    }

    const createReport = async (reportData: ReportModel) => {
        try {
            setLoading(true)
            const params = generateParams(reportData)
            const response = await axiosInstance.get(ApiRoutes.ORDERS_REPORT + "?" + objectToIndexedQueryString(params))
            Notification({
                message: "",
                description: "Report generated successfully",
                type: NotificationTypes.SUCCESS,
            });
            window.open(response.data["url"])
            return response.data["url"]
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const createInvitesReport = async (reportData: ReportModel) => {
        try {
            setLoading(true)
            const params = generateParams(reportData)
            const response = await axiosInstance.get(ApiRoutes.INVITES_REPORT + "?" + objectToIndexedQueryString(params))
            Notification({
                message: "",
                description: "Report generated successfully",
                type: NotificationTypes.SUCCESS,
            });
            window.open(response.data["url"])
            return response.data["url"]
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const createPaymentReport = async (reportData: ReportModel) => {
        try {
            setLoading(true)
            const params = generateParams(reportData)
            const response = await axiosInstance.get(ApiRoutes.PAYMENTS_REPORT + "?" + objectToIndexedQueryString(params))
            Notification({
                message: "",
                description: "Report generated successfully",
                type: NotificationTypes.SUCCESS,
            });
            window.open(response.data["url"])
            return response.data["url"]
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        createReport,
        createInvitesReport,
        createPaymentReport,
    }
}

export default ReportService