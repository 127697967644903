import { serializable, alias, primitive, object, list } from "serializr";
import { Subscription } from "../Subscription/subscription.model";
import { AccountManager } from "../AccountManager/accountManager.model";

export class KYCBasicInfo {
  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("middle_name", primitive()))
  middleName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("KRA_pin_number", primitive()))
  KRAPinNumber?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("id_number", primitive()))
  idNumber?: string;

  @serializable(alias("id_type", primitive()))
  idType?: string;

  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;

  @serializable(alias("district_of_birth", primitive()))
  districtOfBirth?: string;

  @serializable(alias("place_of_issue", primitive()))
  placeOfIssue?: string;

  @serializable(alias("date_of_issue", primitive()))
  dateOfIssue?: string;

  @serializable(alias("district", primitive()))
  district?: string;

  @serializable(alias("division", primitive()))
  division?: string;

  @serializable(alias("location", primitive()))
  location?: string;

  @serializable(alias("sub_location", primitive()))
  subLocation?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("user_role", primitive()))
  userRole?: string;

  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("is_from_iprs", primitive()))
  isFromIprs?: boolean;

  @serializable(alias("attempts_remaining", primitive()))
  attemptsRemaining?: number;
}

export class BankDetail {
  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("account_number", primitive()))
  accountNumber?: string;

  @serializable(alias("bank_name", primitive()))
  bankName?: string;

  @serializable(alias("branch_name", primitive()))
  branchName?: string;

  @serializable(alias("is_savings_account", primitive()))
  isSavingsAccount?: string;

  @serializable(alias("account_type", primitive()))
  accountType?: string;
}

export class EmergencyContact {
  @serializable(alias("name", primitive()))
  name?: number;

  @serializable(alias("phone", primitive()))
  phone?: string;
}

export class BeneficiaryDetail {
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("relation", primitive()))
  relation?: string;
}
export class availableFilters { }

export class status { }

export class CustomerDetails {
  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("uid", primitive()))
  uId?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("middle_name", primitive()))
  middleName?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("current_step", primitive()))
  currentStep?: string;

  @serializable(alias("last_updated_on", primitive()))
  lastUpdate?: string;

  @serializable(alias("invite_code", primitive()))
  inviteCode?: string;

  @serializable(alias("role", primitive()))
  role?: string;

  @serializable(alias("joined_date", primitive()))
  joinedDate?: string;

  @serializable(alias("date_submitted", primitive()))
  submittedDate?: string;

  @serializable(alias("invite_code", primitive()))
  invitationCode?: string;

  @serializable(alias("account_manager_id", primitive()))
  accountManagerId?: number;

  @serializable(alias("dial_code", primitive()))
  dialCode?: string;

  @serializable(alias('date_of_birth', primitive()))
  dateOfBirth?: string;

  @serializable(alias('date_of_issue', primitive()))
  dateOfIssue?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("subscription", primitive()))
  subscription?: string;

  @serializable(alias("dynamic_wallet_balance", primitive()))
  dynamicWalletBalance?: number;

  @serializable(alias("static_wallet_balance", primitive()))
  staticWalletBalance?: number;

  @serializable(alias("holdings", primitive()))
  holdings?: number;

  @serializable(alias("is_blacklisted", primitive()))
  isBlacklisted?: 0 | 1;

  @serializable(alias("kyc_complete_status", primitive()))
  kycCompleteStatus?: number;

  @serializable(alias("is_kyc_accepted", primitive()))
  isKycAccepted?: number;

  @serializable(alias("member_since", primitive()))
  memberSince?: string;

  @serializable(alias("active_subscription", object(Subscription)))
  activeSubscription?: Subscription;

  @serializable(alias("kyc_basic_info", object(KYCBasicInfo)))
  kycBasicInfo?: KYCBasicInfo;

  @serializable(alias("kyc_bank_details", object(BankDetail)))
  kycBankDetails?: BankDetail;

  @serializable(alias("beneficiary_detail", object(BeneficiaryDetail)))
  beneficiaryDetail?: BeneficiaryDetail;

  @serializable(alias("emergency_contact", object(EmergencyContact)))
  emergencyContact?: EmergencyContact;

  @serializable(alias("available_filters", object(availableFilters)))
  availableFilters?: EmergencyContact;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("registration_id", primitive()))
  registrationId?: string;
  
  @serializable(alias("auto_dividend_invest_option", primitive()))
  autoDividendInvestOption?: 0 | 1;
}

export class Customer extends CustomerDetails {
  @serializable(alias("account_manager", object(AccountManager)))
  accountManager?: AccountManager;
}

export class KycIdProof {
  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("photograph", primitive()))
  photograph?: string;

  @serializable(alias("kenyan_id_proof", primitive()))
  kenyanIdProof?: string;

  @serializable(alias("KRA_pin", primitive()))
  kraPin?: string;

  @serializable(alias("photograph_verified_by", primitive()))
  photographVerifiedBy?: string;

  @serializable(alias("photograph_rejection_reason", primitive()))
  photographRejectionReason?: string;

  @serializable(alias("kenyan_id_proof_verified_by", primitive()))
  kenyanIdProofVerifiedBy?: string;

  @serializable(alias("kenyan_id_proof_rejection_reason", primitive()))
  kenyanIdProofRejectionReason?: string;

  @serializable(alias("KRA_pin_verified_by", primitive()))
  kraPinVerifiedBy?: string;

  @serializable(alias("KRA_pin_rejection_reason", primitive()))
  kraPinRejectionReason?: string;
}
export class KycChamaUsers {
  @serializable(alias("photograph", primitive()))
  photograph?: string;

  @serializable(alias("kenyan_id_proof", primitive()))
  kenyanIdProof?: string;

  @serializable(alias("KRA_pin_number", primitive()))
  kraPinNumber?: string;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("middle_name", primitive()))
  middleName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("person", primitive()))
  person?: number;
}

export class KycVideoProof {
  @serializable(alias("verification_code", primitive()))
  verificationCode?: string;

  @serializable(alias("verification_video", primitive()))
  verificationVideo?: string;

  @serializable(alias("verified_by", primitive()))
  verifiedBy?: string;

  @serializable(alias("rejection_reason", primitive()))
  rejectionReason?: string;
}

class kycRiskAssesmentOption {
  @serializable(alias("option", primitive()))
  option?: string;

  @serializable(alias("is_answer", primitive()))
  isAnswer?: string;
}

export class KycRiskAssesment {
  @serializable(alias("question_id", primitive()))
  questionId?: string;

  @serializable(alias("question", primitive()))
  question?: string;

  @serializable(alias("is_multiselect", primitive()))
  isMultiselect?: string;

  @serializable(alias("options", list(object(kycRiskAssesmentOption)) || null))
  options?: kycRiskAssesmentOption[];
}

export class CustomerKycDetails {
  @serializable(alias("kyc_id_proof", object(KycIdProof)))
  kycIdProof?: KycIdProof;

  @serializable(alias("kyc_video_proof", object(KycVideoProof)))
  kycVideoProof?: KycVideoProof;

  @serializable(alias('kyc_chama_users', list(object(KycChamaUsers))))
  kycChamaUsers?: KycChamaUsers[];

  @serializable(alias("kyc_risk_assesment", list(object(KycRiskAssesment))))
  kycRiskAssesment?: KycRiskAssesment[];
}

export class DocumentVerificationModel {
  @serializable(alias("verification_video", primitive()))
  video?: string | false;

  @serializable(alias("KRA_pin", primitive()))
  kraPin?: string | false;

  @serializable(alias("kenyan_national_id", primitive()))
  kenyanNationalId?: string | false;

  @serializable(alias("photograph", primitive()))
  photograph?: string | false;
}

export class DocumentVerificationRequestModal {
  @serializable(alias("kyc_video_proof_message", primitive()))
  video?: string | undefined;

  @serializable(alias("kyc_id_proof_KRA_pin_message", primitive()))
  kraPin?: string | undefined;

  @serializable(alias("kyc_id_proof_kenyan_id_proof_message", primitive()))
  kenyanNationalId?: string | undefined;

  @serializable(alias("kyc_id_proof_photograph_message", primitive()))
  photograph?: string | undefined;

  @serializable(alias("accept", primitive()))
  accept?: boolean;
}

export class customerHoldings {
  @serializable(alias("total_investment", primitive()))
  totalInvestment?: number | undefined;

  @serializable(alias("holdings", primitive()))
  units?: number | undefined;

  @serializable(alias("dividends_earned", primitive()))
  dividendsEarned?: number | undefined;

  @serializable(alias("capital_appreciation", primitive()))
  capitalAppreciation?: number | undefined;

  @serializable(alias("interest_percent", primitive()))
  percentReturn?: boolean;

  @serializable(alias("current_share_value", primitive()))
  currentValuation?: boolean;
}
