import React, { FC } from "react";
import AppLayout from "../../../shared/components/Layout/AppLayout";
import AppRouter from "../appWrapperRouter";

interface AppLayoutProps {
}

const AppWrapper: FC<AppLayoutProps> = (props) => {

    return (
        <AppLayout>
            <AppRouter />
        </AppLayout>
    )
}

export default AppWrapper
