import { Col, Form, PageHeader, Row } from 'antd'
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import moment from 'moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Dividend } from '../../../../models/Dividend/dividend.model'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import DatePickerComponent from '../../../../shared/components/DatePickerComponent'
import InputField from '../../../../shared/components/InputField'
import TableComponent from '../../../../shared/components/TableComponent'
import OTPForm from '../../../Auth/LoginForm/OTPForm'
import "./unitRate.scss"

interface UnitRatesProps { }

const columns = [
    {
        dataIndex: "createdDate",
        key: "createdDate",
        title: "Issued on",
        render: (val: string) => val && moment(val)?.format("DD MMM, YYYY hh:mm A")
    },
    {
        dataIndex: "date",
        key: "date",
        title: "Assessment date",
        render: (val: string) => val && moment(val)?.format("DD MMM, YYYY")
    },
    {
        dataIndex: "price",
        key: "price",
        title: "Unit price",
        render: (val: number) => `${(val || 0).toFixed(3)} KES`
    },
    {
        dataIndex: "name",
        key: "name",
        title: "Issued by",
    },
]

const UnitRates: FC<UnitRatesProps> = () => {

    const {
        loading,
        fetchOtp,
        sharePrices,
        submitting,
        fetchUnitRates,
        createUnitRates,
    } = ConfigurationService()

    const [otpModalVisible, setOtpModal] = useState<boolean>(false)

    const formikRef = useRef<FormikProps<Dividend>>(null)

    useEffect(() => {
        fetchUnitRates()
    }, [])

    const submitHandler = async (values: Dividend) => {
        if (!values.date || !values.price) return
        const response = await fetchOtp(values.date, values.price, true)
        setOtpModal(!!response)
    }

    const handleCreateDividend = async (values: FormikValues) => {
        if (!formikRef.current) return
        const response = await createUnitRates({ ...formikRef.current?.values, otp: values.otp })
        if (!response) return
        setOtpModal(false)
        fetchUnitRates()
        formikRef.current.resetForm()
    }

    return (
        <div className="unit-price__container">
            <div className="unit-price__form">
                <Formik
                    innerRef={formikRef}
                    initialValues={new Dividend()}
                    onSubmit={submitHandler}>
                    {({ setFieldValue, values, submitForm }) => <Form>
                        <Row gutter={[16, 16]}>
                            <Col span={5}>
                                <InputField
                                    name='price'
                                    type='number'
                                    placeholder='Enter price'
                                    title='Unit Price'
                                />
                            </Col>
                            <Col span={5}>
                                <DatePickerComponent
                                    name='date'
                                    value={values.date}
                                    title='Assessment Date'
                                    onChange={((_, dateString) => {
                                        setFieldValue("date", dateString);
                                    })}
                                />
                            </Col>
                            <Col className="unit-price__form--actions">
                                <ButtonComponent
                                    type='primary'
                                    htmlType='submit'
                                    loading={submitting}
                                    onClick={submitForm}
                                >Start</ButtonComponent>
                            </Col>
                        </Row>
                    </Form>}
                </Formik>
            </div>
            <div className="unit-price__list">
                <TableComponent
                    columns={columns}
                    rowKey={"id"}
                    dataSource={sharePrices}
                    loading={loading}
                />
            </div>
            <OTPForm
                loading={loading}
                otpModalVisibility={otpModalVisible}
                otpSubmissionHandler={handleCreateDividend}
            ></OTPForm>
        </div>
    )
}

export default UnitRates