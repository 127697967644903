import React, { FC, useEffect, useRef, useState } from "react";
import { NumberLiteralType } from "typescript";
import "./dynamicText.scss";

interface DynamicTextProps {
  width: number | string;
  fontSize?: number;
  text: string;
  className?: string;
}
const DynamicText: FC<DynamicTextProps> = (props) => {
  const { width, text, fontSize = 16, className = "" } = props;

  const [font, setFont] = useState<number>(fontSize);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      checkForFitAndReduce(element);
    }
  }, [font, text]);

  const checkForFitAndReduce = (element: HTMLDivElement) => {
    if (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    ) {
      const fontSize = font - 0.5;
      setFont(fontSize);
      element.style.fontSize = fontSize + "px";
    }
  };

  return (
    <div
      ref={ref}
      className={`dynamic-text ${className}`}
      style={{ width, fontSize }}
    >
      {text}
    </div>
  );
};

export default DynamicText;
