import React, { Fragment } from "react";
import { KycRiskAssesment } from "../../../models/Customer/customer.model";
import "./riskAssessmentQuestion.scss"

interface RiskAssessmentQuestionProps {
    questions: KycRiskAssesment[]
}

const RiskAssessmentQuestion = (props: RiskAssessmentQuestionProps) => {

    const {
        questions,
    } = props

    return <div className="risk-assessment__container">
        <ol>
            {
                questions.map(question =>
                    <li className="risk-assessment" key={question?.questionId}>
                        <p className="risk-assessment--question">{question.question}</p>
                        <p className="risk-assessment--answer">{question.options?.find(ans => ans.isAnswer)?.option}</p>
                    </li>

                )
            }

        </ol>
    </div>
}

export default RiskAssessmentQuestion