import * as Yup from "yup";
import { REGEX } from "../../../../../shared/utils/regex";

export const internalUsersValidationSchema = Yup.object().shape({
    role: Yup.string()
        .required('Type is required!'),
    name: Yup.string()
        .required('Name is required!'),
    phone: Yup.string()
        .required('Mobile number is required!'),
    // status: Yup.string()
    //     .required('Status is required!'),
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!')
        .matches(REGEX.PASSWORD, "One Uppercase, One Lowercase, One Number and one special case Character")
})