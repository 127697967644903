import React, { FC } from "react";
import AuthService from "../../../../services/AuthService/auth.service";
import Navbar from "../../Navbar";
import "./appLayout.scss";

interface AppLayoutProps {}

const AppLayout: FC<AppLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div className="app__container">
      <div className="nav__container">
        <Navbar />
      </div>

      <div className="route__container">{children}</div>
    </div>
  );
};

export default AppLayout;
