import { serializable, alias, object, list, primitive } from "serializr";
import { PaginationWrapper } from "../PaginationWrapper/paginationWrapper.model";

import { CustomerDetails } from "./customer.model";

export class PaginatedCustomerDetails extends CustomerDetails {
  @serializable(alias("account_manager", primitive()))
  accountManager?: string;
}

export class PaginatedCustomer extends PaginationWrapper {
  @serializable(alias("data", list(object(PaginatedCustomerDetails))))
  data?: PaginatedCustomerDetails[];
}
