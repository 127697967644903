import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";
const { TextArea } = Input;
interface InputFieldProps {
  title?: string;
  type: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  addonAfter?: any;
  addonBefore?: any;
  disablepaste?: boolean;
  className?: string;
}
const InputField: FC<InputFieldProps> = (props) => {
  const { name, title, disablepaste, className, type } = props;
  const properties: any = { ...props };
  if (disablepaste) {
    properties.disablepaste = properties.disablepaste.toString();
    properties.onPaste = () => false;
    properties.onDrop = () => false;
  }
  return (
    <div
      className={`input-field__wrapper mb-5 ${className} ${
        props.addonBefore && "no-border-right"
      }`}
    >
      <label htmlFor={name}>{title}</label>
      {type === "textarea" ? (
        <Field as={TextArea} {...properties} />
      ) : (
        <Field
          as={Input}
          {...properties}
          onPaste={() => false}
          style={{ marginTop: "0.5rem" }}
        />
      )}
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};
export default InputField;
