import { Button, Col, Form, Row } from "antd";
import moment from "moment";
import React, { FC, useEffect, useRef } from "react";
import BackgroundImage from "../../../../assets/images/kyc-page-1.png";
import "./customerDetails.scss";
import { Customer, KYCBasicInfo } from "../../../models/Customer/customer.model";
import AlertModal from "../AlertModal";
import { Formik, FormikProps } from "formik";
import CustomerService from "../../../services/CustomerService/customer.service";
import DatePickerComponent from "../DatePickerComponent";

interface CustomerDetailsProps {
	visible: boolean
	onOk: (values?: Customer) => void
	onCancel: () => void
	customer: KYCBasicInfo
}

const CustomerDetails: FC<CustomerDetailsProps> = (props) => {

	const {
		onOk,
		visible,
		customer,
		onCancel,
	} = props

	const { updateCustomerDetails, approving } = CustomerService();

	const formikRef = useRef<FormikProps<Customer>>(null)

	const handleNextPage = async (values?: Customer) => {
		//Need refactor
		if ((!customer.dateOfIssue || !customer.dateOfBirth)) {
			if (values?.dateOfBirth && values.dateOfIssue) {
				const response = values && await updateCustomerDetails(values)
				response && onOk()
			}
		} else {
			const response = values && await updateCustomerDetails(Object.assign(new Customer(), {
				...values,
				dateOfBirth: moment(values.dateOfBirth).format("Y-MM-DD"),
				dateOfIssue: moment(values.dateOfIssue).format("Y-MM-DD"),
			}))
			response && onOk()
		}
	};

	return (
		<Formik
			initialValues={Object.assign(new Customer(), customer)}
			onSubmit={handleNextPage}
			innerRef={formikRef}
			enableReinitialize
		>
			{({ values, setFieldValue, submitForm }) => {
				return <Form>
					<AlertModal
						title="Review Member Details"
						okText="Sync Details"
						visible={visible}
						confirmLoading={approving}
						onOk={submitForm}
						onCancel={onCancel}
					>
						<div className="customer-details">
							<Row className="details__wrapper">
								<Col span={24}>
									<h1>
										{customer?.firstName} {customer?.middleName}{" "}
										{customer?.lastName}
									</h1>{" "}
								</Col>
								<Col span={12}>
									<label htmlFor="">ID Number</label>
									<p>{customer?.kenyanNationalNumber}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Gender</label>
									<p>{customer?.gender}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">DOB</label>
									{!customer?.dateOfBirth
										? <DatePickerComponent
											disableDates="future"
											name={"dateOfBirth"}
											value={values.dateOfBirth}
											placeHolder={"Enter date of birth"}
											onChange={(value: any, dateString: string | string[]) => setFieldValue("dateOfBirth", dateString)} />
										: <p className="mt-2">{customer?.dateOfBirth && moment(customer?.dateOfBirth).format("DD MMM YYYY")}</p>
									}
								</Col>
								<Col span={12}>
									<label htmlFor="">District of Birth</label>
									<p>{customer?.districtOfBirth}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Place of issue</label>
									<p>{customer?.placeOfIssue}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Date of Issue</label>
									{!customer?.dateOfIssue
										? <DatePickerComponent
											disableDates="future"
											name={"dateOfIssue"}
											value={values.dateOfIssue}
											placeHolder={"Enter date of issue"}
											onChange={(date, dateString) => setFieldValue("dateOfIssue", dateString)} />
										: <p className="mt-2">{customer?.dateOfIssue && moment(customer?.dateOfIssue).format("DD MMM YYYY")}</p>
									}
								</Col>
								<Col span={12}>
									<label htmlFor="">District</label>
									<p>{customer?.district}</p>
								</Col>{" "}
								<Col span={12}>
									<label htmlFor="">Division</label>
									<p>{customer?.division}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Location</label>
									<p>{customer?.location}</p>
								</Col>
								<Col span={12}>
									<label htmlFor="">Sub-location</label>
									<p>{customer?.subLocation}</p>
								</Col>
							</Row>
						</div>
					</AlertModal>
				</Form>
			}}
		</Formik>
	);
};

export default CustomerDetails

