import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { AccountManager } from "../../models/AccountManager/accountManager.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AllocateManager } from "../../models/AccountManager/allocateManager.model";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";

const AccountManagerService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [accountManagers, setAccountManagers] = useState<AccountManager[]>([]);

  const fetchManagers = async () => {
    setButtonLoading(true);

    try {
      const response = await axiosInstance.get(ApiRoutes.ACCOUNT_MANAGER);
      const data = deserialize(
        AccountManager,
        response.data?.data ?? []
      ) as AccountManager[];
      setAccountManagers(data);
      return data;
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  const assignAccountManager = async (payload: AllocateManager) => {
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        ApiRoutes.ALLOCATE_MANAGER,
        serialize(AllocateManager, JSON.parse(JSON.stringify(payload)))
      );

      Notification({
        message: "Success",
        description: response.data?.message || "Users allocated successfully.",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    buttonLoading,
    fetchManagers,
    assignAccountManager,
    accountManagers,
  };
};

export default AccountManagerService;
