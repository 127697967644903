import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import UploadComponent from "../UploadComponent";
import PdfIcon from './../../../assets/icons/pdf.svg'
import "./documentViewer.scss"

interface DocumentViewerProps {
    document: string
    hideStatus: boolean
    // enableUpload?: boolean
    name: string
    onClick?: () => void
    status: string
    customerId?: number
    keyName?: string
    refreshData: Function
}

const DocumentViewer = (props: DocumentViewerProps) => {

    const {
        document,
        hideStatus,
        // enableUpload,
        name,
        onClick,
        status,
        keyName,
        customerId,
        refreshData,
    } = props

    return <div className="document-viewer" onClick={onClick}>
        <iframe src={document}></iframe>
        <div className="document-viewer--details">
            <span className="title">
                <img src={PdfIcon} alt="" />
                {name}
            </span>
            <div className="document-viewer__actions" onClick={e => e.stopPropagation()}>
                {customerId && <UploadComponent
                    refreshData={refreshData}
                    customerId={customerId}
                    keyName={keyName} />}
                <span className={`kyc--status ${status}`}>
                    {status}
                </span>
            </div>
        </div>
    </div>
}

export default DocumentViewer