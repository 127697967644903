import { primitive, serializable, alias, list } from "serializr";
import { ReportTypes } from "../../enums/reportTypes.enum";

export class ReportModel {
    @serializable(alias("report_type", primitive()))
    reportType?: ReportTypes

    @serializable(alias("start_date", primitive()))
    startDate?: string

    @serializable(alias("end_date", primitive()))
    endDate?: string

    @serializable(alias("order_types", list(primitive())))
    orderTypes?: string[]

    @serializable(alias("roles", list(primitive())))
    roles?: string[]

    @serializable(alias("subscriptions", list(primitive())))
    subscriptions?: string[]

    @serializable(alias("order_statuses", list(primitive())))
    orderStatuses?: string[]
}