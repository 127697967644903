export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GET_USER: "/users/me",
  USER_LOGIN: "/admin/login",
  USER_LOGIN_GET_OTP: "/admin/login/get-otp",
  FORGOT_PASSWORD: "/admin/forgot-password",
  INVITE: "/admin/invite",
  USER_LOGOUT: "/users/logout",
  USER_ROLE_INFO: "/admin/iprs",
  CUSTOMERS: "/admin/customers",
  ORDERS: "/admin/orders",
  PENDING_CUSTOMERS: "/admin/customers/pending-approval",
  INCOMPLETE_CUSTOMERS: "/admin/customers/incomplete",
  CUSTOMERS_REPORT: "/admin/customers/get-customer-report",
  MEMBERS_REPORT: "/admin/customers/get-member-report",
  CUSTOMER_DETAILS: "/admin/customers/:customerId/basic-details",
  CUSTOMER_KYC_DETAILS: "/admin/customers/:customerId/kyc-details",
  CUSTOMER_KYC_VERIFICATION:
    "/admin/customers/:customerId/kyc-verification-status",
  USER_ROLE_INFO_UPDATE: "/admin/iprs",
  CONFIGURATION_INTERNAL_USERS: "/admin/configuration/internal-user",
  CONFIGURATION_INTERNAL_USERS_SPECIFIC:
    "/admin/configuration/internal-user/:userId",
  CONFIGURATION_CREATABLE_USERS:
    "/admin/configuration/internal-user-creatable-roles",
  DIVIDENDS: "/admin/configuration/dividend",
  UNIT_RATE: "/admin/configuration/share-price",
  CONFIGURATION_OTP: "admin/configuration/send-otp",
  CUSTOMER_KYC_UPLOAD: "admin/kyc/id-proof",
  VERIFICATION_CODE: "admin/kyc/video-proof/verification-code",
  GENERATE_UPLOAD_LINK: "admin/kyc/video-proof/generate-upload-link",
  VIDEO_UPLOAD_SUCCESS: "admin/kyc/video-proof",
  FAQ: "/information/faq",
  TERMS_AND_CONDITIONS: "/information/general/terms_and_conditions",
  WITHDRAWS: "/admin/withdraw_requests",
  CUSTOMER_WITHDRAWS: "/admin/customers/withdraw_requests",
  WITHDRAW_PROCESS: "/admin/withdraw_requests/:requestId/process",
  CREATE_WITHDRAW_OTP: "/admin/withdraw_requests/send-otp",
  VERIFY_CREATE_WITHDRAW: "/admin/withdraw_requests/verify-create",
  GET_USER_WALLET: "/users/my-wallet",
  GET_USER_WALLET_HISTORY: "/users/wallet/history",
  PAYMENT: "/admin/payment",
  PAYMENT_METHODS: "/admin/payment/methods",
  GET_HOLDINGS_WALLET: "/wallet-and-holdings",
  WALLET_DETAILS: "/admin/customers/:customerId/wallet-and-holdings",
  WALLET_HISTORY: "/admin/customers/:customerId/wallet-history",
  CUSTOMER_BUY_SELL_ORDER: "/admin/customers/:customerId/orders",
  ACCOUNT_MANAGER: "/admin/customers/allocate-manager-id",
  ALLOCATE_MANAGER: "/admin/customers/allocate-manager",

  ORDERS_REPORT: "/admin/orders/get-order-process-report",
  ORDERS_REJECT: '/admin/orders/:id/cancel',
  INVITES_REPORT: "/admin/invite/get-invite-report",
  PAYMENTS_REPORT: "/admin/payment/get-payment-report",


  META_SUBSCRIPTIONS: "/admin/subscription/plans",

  SUBSCRIPTION_DETAILS: "/admin/subscription/:subscriptionId",
  UPGRADE_SUBSCRIPTION: "/admin/subscription",
  CHANGE_PASSWORD: "/users/change-password",
  SHARES_BREAKDOWN: "/admin/customers/:customerId/order/option/breakdown",
  SHARES_SELL_BREAKDOWN: "/admin/customers/:customerId/sell-order/option/breakdown",
  PLACE_ORDER: "/admin/customers/:customerId/place-order",

  CANCEL_PAYMENTS: "/admin/payment/:id/cancel",
  UPDATE_KYC: "/admin/customers/:id/kyc-basic-info"
};
