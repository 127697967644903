import React from "react";

export const getPaginationFooter = (total: number, range: [number, number]) => {
  return (
    <p>
      Showing <b>{` ${range[0] ?? 0} - ${range[1] ?? 0}`}</b> of{" "}
      <b>{`${total.toLocaleString() ?? 0}`}</b>
    </p>
  );
};
