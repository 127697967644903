import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import AppWrapper from "../views/App/AppWrapper";
import TermsAndContionsModal from "../shared/components/TermsAndContionsModal";

export const appHistory = createBrowserHistory();

interface Props{
	redirectUrl?: string;
}
const AppRoutes = ({
	redirectUrl
}:Props) => {
	const routes: RouterProps[] = [
		{ exact: false, path: appRoutes.AUTH, component: AuthWrapper },
		{
			exact: false,
			path: appRoutes.APP,
			component: isAuthenticated(AppWrapper),
		},
	];

	return (
		<div>
			<TermsAndContionsModal />
			<Router history={appHistory}>
				<Switch>
					{routes.map((route, index) => (
						<Route
							key={index}
							{...route}
							component={route.component as FC}
						/>
					))}
					<Route
						path="*"
						render={() => <Redirect to={appRoutes.LOGIN} />}
					/>
					{redirectUrl?<Redirect to={redirectUrl} />:null}
				</Switch>
			</Router>
		</div>
	);
};

export default AppRoutes;
