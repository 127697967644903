import { Customer } from "../../../../models/Customer/customer.model";
import { getCapitalizeText } from "../../../../shared/utils/getCapitalizeText";
import { getBlackListStatus } from "../../../../shared/utils/getBlackListStatus";

const allCustomersColumns = [
  {
    title: "ID",
    dataIndex: "uId",
    key: "id",
    width: 100,
    sorter: true,
    render: (id: number) => `${id}`,
  },
  {
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
    width: 180,
    ellipsis: true,
    sorter: true,
    render: (_: string, customer: Customer) => getCapitalizeText(customer),
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    width: 80,
    sorter: true,
  },
  {
    title: "Member since",
    dataIndex: "joinedDate",
    key: "joinedDate",
    width: 110,
    sorter: true,
  },
  {
    title: "Account manager",
    dataIndex: "accountManager",
    key: "accountManager",
    width: 180,
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
    width: 100,
  },

  {
    title: "Subscription",
    dataIndex: "subscription",
    key: "subscription",
    width: 100,
    sorter: true,
  },
  {
    title: "Units",
    dataIndex: "holdings",
    key: "holdings",
    width: 70,
  },

  {
    title: "Wallet",
    dataIndex: "dynamicWalletBalance",
    key: "dynamicWalletBalance",
    width: 80,
  },

  {
    title: "Status",
    dataIndex: "isBlacklisted",
    key: "isBlacklisted",
    width: 70,
    render: getBlackListStatus,
  },
];

const pendingCustomersColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "id",
    width: 200,
    ellipsis: true,
    sorter: true,
    render: (_: string, customer: Customer) => getCapitalizeText(customer),
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    width: 100,
    sorter: true,
  },
  {
    title: "Account manager",
    dataIndex: "accountManager",
    key: "accountManager",
    width: 180,
  },
  {
    title: "Date Submitted",
    dataIndex: "submittedDate",
    key: "submittedDate",
    width: 100,
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 200,
    ellipsis: true,
    sorter: true,
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
    width: 100,
  },
  {
    title: "Subscription",
    dataIndex: "subscription",
    key: "subscription",
    width: 100,
    sorter: true,
  },
];
const incompleteCustomersColumns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 200,
    sorter: true,
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
    width: 100,
  },
  {
    title: "Account manager",
    dataIndex: "accountManager",
    key: "accountManager",
    width: 180,
  },
  {
    title: "Last Updated On",
    dataIndex: "lastUpdate",
    key: "lastupdate",
    width: 100,
    sorter: true,
  },
  {
    title: "Current Step",
    dataIndex: "currentStep",
    key: "currentStep",
    width: 250,
  },
];

export {
  allCustomersColumns,
  incompleteCustomersColumns,
  pendingCustomersColumns,
};
