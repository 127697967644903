import React, { useEffect, useState } from 'react';
import AppRoutes from './routes';
import { Provider } from "react-redux";
import { store } from "./store";
import 'antd/dist/antd.css';
import "./styles/main.scss";
import AuthService from "./services/AuthService/auth.service"
import { LOGIN } from './routes/routeConstants/appRoutes';

const App = () => {

  const {
    getUserDetails,
  } = AuthService()

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    getUserDetails(true, () => setIsSessionExpired(true))
  }, [])

  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes redirectUrl={isSessionExpired ? LOGIN : undefined} />
      </div>
    </Provider>
  );
}

export default App;
