import * as Yup from 'yup';

export const withdrawalApprovalValidation = Yup.object({
    transactionId: Yup
        .string()
        .required("Transaction ID is required!"),
    comments: Yup
        .string()
        .min(3, "Comment is too short")
})
export const withdrawalRejectionValidation = Yup.object({
    comments: Yup
        .string()
        .min(3, "Comment is too short")
})