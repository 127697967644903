export enum WithdrawalModes {
    MPESA = "mpesa",
    BANK = "bank"
}
export enum WithdrawalStatuses {
    APPROVED = "approved",
    REJECTED = "rejected",
    PENDING = "pending"
}

export const WithdrawalModesObject: Record<string, string> = {
    "mpesa": "MPESA",
    "bank": "Bank Account",
    "Bank Account": "Bank",
    "Mpesa": "MPESA"
}