import { serializable, alias, object, list, primitive } from 'serializr';

export class Subscription {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('code', primitive()))
	code?: string;

	@serializable(alias('price', primitive()))
	price?: number;

	@serializable(alias('limit', primitive()))
	limit?: number;

	@serializable(alias('joining_fee', primitive()))
	joiningFee?: number;

	@serializable(alias('subscription_details', list(primitive())))
	description?: string[];

	@serializable(alias('subscription_id', primitive()))
	subscriptionId?: number;

	@serializable(alias('phone', primitive()))
	phone?: number;

	@serializable(alias('use_wallet_only', primitive()))
	useWalletOnly?: boolean;

	@serializable(alias('dial_code', primitive()))
	dialCode?: number = 254;

}

export class SubscriptionResponse {

	@serializable(alias('message', primitive()))
	message?: string;

	@serializable(alias('status_url', primitive()))
	dynamicUrl?: string;

}
export class DynamicUrlResponse {

	@serializable(alias('description', primitive()))
	message?: string;

	@serializable(alias('status', primitive()))
	status?: 'Pending' | 'Failed' | 'Success';

}
export class SubscriptionPaymentDetails {
	@serializable(alias('amount_to_be_paid', primitive()))
	amountToBePaid?: string;

	@serializable(alias('annual_fee', primitive()))
	annualFee?: string;

	@serializable(alias('balance', primitive()))
	balance?: string;

	@serializable(alias('joining_fee', primitive()))
	joiningFee?: string;

}