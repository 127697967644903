import { PaginationWrapper } from './../../models/PaginationWrapper/paginationWrapper.model';
import { Withdraw } from './../../models/Withdraw/withdraw.model';
import axiosInstance from "../../interceptor/axiosInstance";
import { Wallet, WalletHistory } from "./../../models/Wallet/wallet.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { generatePath } from 'react-router-dom';

const WalletService = () => {
  const [wallet, setWallet] = useState<Wallet>();
  const [walletHistory, setWalletHistory] = useState<WalletHistory[]>([]);
  const [withdrawList, setWithdrawList] = useState<Withdraw[]>([]);
  const [withdrawPagination, setWithdrawPagination] = useState<PaginationWrapper>()

  const [walletLoading, setWalletLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSecondarySubmitting, setSecondarySubmitting] = useState(false);
  const [walletHistroyLoading, setWalletHistoryLoading] = useState(false);

  const getWalletDetails = async (customerId: number | string) => {
    setWalletLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.WALLET_DETAILS.replace(":customerId", customerId.toString())
      );

      if (response.data?.success) {
        const data = deserialize(Wallet, response.data["wallets"]);
        setWallet(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.SUCCESS,
      });
    } catch (error) {
    } finally {
      setWalletLoading(false);
    }
  };

  const getWalletHistory = async (
    customerId: number | string,
    wallet_type: string
  ) => {
    setWalletHistoryLoading(true);

    try {
      /* const params = { wallet_type: walletType }; */
      const response = await axiosInstance.get(
        ApiRoutes.WALLET_HISTORY.replace(":customerId", customerId.toString()),
        {
          params: { wallet_type },
        }
      );

      if (response.data?.success) {
        const data = deserialize(
          WalletHistory,
          response.data["wallet_history"]
        ) as WalletHistory[];

        setWalletHistory(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.SUCCESS,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setWalletHistoryLoading(false);
    }
  };

  const getCustomerWithdrawReqs = async (user_id: string | number, additionalParams?: {
    page?: number,
    search?: string,
    sort_dir?: string,
    sort_by?: string
  }) => {
    setWalletLoading(true);
    try {
      const params = { user_id, ...additionalParams }
      const response = await axiosInstance.get(ApiRoutes.CUSTOMER_WITHDRAWS, { params });
      if (response.data?.success) {
        const data = deserialize(Withdraw, response.data["withdraw_requests"]["data"]) as Withdraw[];
        const pagination = deserialize(PaginationWrapper, response.data["withdraw_requests"])
        setWithdrawList(data);
        setWithdrawPagination(pagination)
      }
    } catch (error) {
    } finally {
      setWalletLoading(false);
    }
  };
  const getWithdrawReqs = async (params?: {
    page?: number,
    search?: string,
    sort_dir?: string,
    sort_by?: string
  }) => {
    setWalletLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.WITHDRAWS, { params });
      if (response.data?.success) {
        const data = deserialize(Withdraw, response.data["withdraw_requests"]["data"]) as Withdraw[];
        const pagination = deserialize(PaginationWrapper, response.data["withdraw_requests"])
        setWithdrawList(data);
        setWithdrawPagination(pagination)
      }
    } catch (error) {
    } finally {
      setWalletLoading(false);
    }
  };

  const updateWithdrawReq = async (requestId: number | string, withdraw: Withdraw) => {
    setSubmitting(true);
    try {
      const payload = serialize(Withdraw, withdraw)
      const API_URL = ApiRoutes.WITHDRAW_PROCESS
      const response = await axiosInstance.put(generatePath(API_URL, { requestId }), { ...payload, withdraw_request_id: requestId })
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const createOTPRequest = async (withdrawRequest: Withdraw) => {
    setSecondarySubmitting(true);
    try {
      // withdrawRequest.amountRequested = withdrawRequest.amountRequested?.split(" ")[0]
      const payload = serialize(Withdraw, withdrawRequest)
      const response = await axiosInstance.post(ApiRoutes.CREATE_WITHDRAW_OTP, payload);
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setSecondarySubmitting(false);
    }
  };

  const verifyCreateWithdraw = async (request: Withdraw) => {
    setSubmitting(true);
    try {
      const payload = serialize(Withdraw, request)
      const response = await axiosInstance.post(ApiRoutes.VERIFY_CREATE_WITHDRAW, payload);
      return response.data
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return {
    wallet,
    submitting,
    walletLoading,
    getWalletDetails,
    walletHistory,
    walletHistroyLoading,
    getWalletHistory,
    withdrawList,
    getWithdrawReqs,
    getCustomerWithdrawReqs,
    updateWithdrawReq,
    createOTPRequest,
    verifyCreateWithdraw,
    isSecondarySubmitting,
    withdrawPagination,
  };
};

export default WalletService;
