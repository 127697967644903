import { removeFalsyKeys } from "./../../shared/utils/removeFalsyKeys";
import { deserialize, serialize } from "serializr";
import {
  Customer,
  CustomerKycDetails,
  DocumentVerificationRequestModal,
  KYCBasicInfo,
  customerHoldings,
} from "./../../models/Customer/customer.model";
import {
  CustomerPayment,
  CustomerPaymentMethod,
} from "./../../models/Customer/customerPayment.model";
import { CustomerWallentAndHoldings } from "./../../models/Customer/customerWalletHoldings.model";
import { CustomerWallentHistory } from "./../../models/Customer/customerWalletHistory.model";
import { CustomerBuySellOrders } from "./../../models/Orders/orders.model";
import { useState } from "react";
import { ApiRoutes } from "./../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { PaginatedCustomer } from "../../models/Customer/paginatedCustomer.model";
import { objectToIndexedQueryString } from "../../shared/utils/objectToQueryHelper";
import { ReportModel } from "../../models/ReportModel/report.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import axios from "axios";
import { generatePath } from "react-router-dom";
import { Share, ShareBreakdown } from "../../models/Share/share.model";
import { FetchQueryParams } from "../../shared/types/fetchQueryParams.type";


const CustomerService = () => {
  const [customers, setCustomers] = useState<PaginatedCustomer>();
  const [customer, setCustomer] = useState<Customer>();
  const [kycDetails, setKycDetails] = useState<CustomerKycDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);
  const [customerHolding, setcustomerHolding] = useState<customerHoldings>();
  const [paymentMethod, setPaymentMethod] = useState<CustomerPaymentMethod[]>(
    []
  );
  const [buySellDetail, setBuySellDetail] = useState<CustomerBuySellOrders>();

  const [walletAndHolding, setWalletAndHolding] =
    useState<CustomerWallentAndHoldings>();

  const [walletHistory, setwalletHistory] = useState<CustomerWallentHistory>();
  const [changePasswordButtonLoading, setChangePasswordButtonLoading] =
		useState(false);
  const [share, setShare] = useState<ShareBreakdown>();

  const fetchCustomers = async (
    params: FetchQueryParams = {
      customerId: "",
      filters: {},
      page: "",
      search: "",
      sort_by: "",
      sort_direction: "",
    }
  ) => {
    setLoading(true);
    setCustomers(undefined);
    const query = objectToIndexedQueryString(params);

    try {
      const response = await axiosInstance.get(
        ApiRoutes.CUSTOMERS + "?" + query
      );
      const data = deserialize(
        PaginatedCustomer,

        response.data["customers"]
      );
      setCustomers(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingCustomers = async (
    params: FetchQueryParams = {
      customerId: "",
      filters: {},
      page: "",
      search: "",
    }
  ) => {
    setLoading(true);
    setCustomers(undefined);
    const query = objectToIndexedQueryString(params);

    try {
      const response = await axiosInstance.get(
        ApiRoutes.PENDING_CUSTOMERS + "?" + query
      );
      const data = deserialize(
        PaginatedCustomer,

        response.data["customers"]
      );
      setCustomers(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchIncompleteCustomers = async (
    params: FetchQueryParams = {
      customerId: "",
      filters: {},
      page: "",
      search: "",
    }
  ) => {
    setLoading(true);
    setCustomers(undefined);
    const query = objectToIndexedQueryString(params);

    try {
      const response = await axiosInstance.get(
        ApiRoutes.INCOMPLETE_CUSTOMERS + "?" + query
      );
      const data = deserialize(
        PaginatedCustomer,

        response.data["customers"]
      );
      setCustomers(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchSingleCustomerBasicDetails = async (
    customerId: number | string
  ) => {
    setCustomer(undefined);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.CUSTOMER_DETAILS.replace(":customerId", customerId.toString())
      );
      const data = deserialize(Customer, response.data["customer"]);
      console.log(data);
      setCustomer(data);
    } catch (error) {
    } finally {
    }
  };
  const fetchSingleCustomerKycDetails = async (customerId: number | string) => {
    setKycDetails(undefined);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.CUSTOMER_KYC_DETAILS.replace(
          ":customerId",
          customerId.toString()
        )
      );
      const data = deserialize(CustomerKycDetails, {
        ...response.data["customer"],
        kyc_chama_users: response.data["customer"]["kyc_chama_users"] || [],
      });
      setKycDetails(data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const verifyKycDocuments = async (customerId: number | string, data: any) => {
    setApproving(true);
    data.accept = Object.values(data).every((ele) => !ele);
    const serialisedData = serialize(DocumentVerificationRequestModal, data);
    try {
      const response = await axiosInstance.put(
        ApiRoutes.CUSTOMER_KYC_VERIFICATION.replace(
          ":customerId",
          customerId.toString()
        ),
        serialisedData
      );
    } catch (error) {
      // console.log(error);
      throw error;
    } finally {
      setApproving(false);
    }
  };

  const updateCustomerDetails = async (basicInfo: KYCBasicInfo) => {
    setApproving(true);
    try {
      const payload = serialize(KYCBasicInfo, basicInfo);
      const response = await axiosInstance.put(
        ApiRoutes.USER_ROLE_INFO_UPDATE,
        payload
      );
      return true;
    } catch (error) {
      console.log(error);
    } finally {
      setApproving(false);
    }
  };

  const updateNationalNumber = async (params: { id: string; type: any }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.USER_ROLE_INFO, {
        params,
      });
      if (response.data.success) {
        console.log(response.data["iprs"]["data"]);

        const customer = deserialize(
          KYCBasicInfo,
          response.data["iprs"]["data"]
        );
        const url = response.data["iprs"]["url"];
        // setCustomer(customer);
        return {
          customer,
          url,
        };
      }
      Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
      return null
    } finally {
      setLoading(false);
    }
  };

  const syncCustomerLookupData = async (
    url: string,
    user_id?: number | string
  ) => {
    try {
      const data = {
        user_id,
      };
      const response = await axiosInstance.put(url, data);
      if (response.data.success) {
        return Notification({
          message: "",
          description: response.data.message,
          type: NotificationTypes.SUCCESS,
        });
      }
      return Notification({
        message: "",
        description: response.data.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
    }
  };

  const addMoneyWallet = async (
    customerData: CustomerPayment,
    onSuccess: () => void
  ) => {
    setLoading(true);
    try {
      const customer = serialize(CustomerPayment, customerData);
      const response = await axiosInstance.post(ApiRoutes.PAYMENT, customer);

      if (response.data?.success) {
        Notification({
          message: "",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
        return true;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentMethod = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(ApiRoutes.PAYMENT_METHODS);
      const data = deserialize(
        CustomerPaymentMethod,
        response.data["manual_payment_methods"]
      ) as CustomerPaymentMethod[];
      setPaymentMethod(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerBuySellOrder = async (
    customerId: string | number,
    params: {
      is_sell_order: string;
      page?: number | string;
    }
  ) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.CUSTOMER_BUY_SELL_ORDER.replace(
          ":customerId",
          customerId.toString()
        ),
        {
          params,
        }
      );
      const data = deserialize(
        CustomerBuySellOrders,
        response.data["orders"]
      ) as CustomerBuySellOrders;
      setBuySellDetail(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createCustomerReport = async (reportData: ReportModel) => {
    try {
      setLoading(true);
      const formattedRequest = serialize(ReportModel, reportData);
      const { start_date, end_date } = formattedRequest;
      delete formattedRequest.start_date;
      delete formattedRequest.end_date;
      const params = {
        start_date,
        end_date,
        filters: formattedRequest,
      };
      !params.start_date && delete params.start_date;
      !params.end_date && delete params.end_date;
      const response = await axiosInstance.get(
        ApiRoutes.CUSTOMERS_REPORT + "?" + objectToIndexedQueryString(params)
      );
      window.open(response.data["url"]);
      Notification({
        message: "",
        description: "Report generated successfully",
        type: NotificationTypes.SUCCESS,
      });
      return response.data["url"];
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createMemberReport = async (reportData: ReportModel) => {
    try {
      setLoading(true);
      const formattedRequest = serialize(ReportModel, reportData);
      const { start_date, end_date } = formattedRequest;
      delete formattedRequest.start_date;
      delete formattedRequest.end_date;
      const params = {
        start_date,
        end_date,
        filters: formattedRequest,
      };
      !params.start_date && delete params.start_date;
      !params.end_date && delete params.end_date;
      const response = await axiosInstance.get(
        ApiRoutes.MEMBERS_REPORT + "?" + objectToIndexedQueryString(params)
      );
      window.open(response.data["url"]);
      Notification({
        message: "",
        description: "Report generated successfully",
        type: NotificationTypes.SUCCESS,
      });
      return response.data["url"];
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const uploadDocuments = async (payload: {
    user_id: number;
    KRA_pin?: File;
    photograph?: File;
    kenyan_id_proof?: File;
  }) => {
    try {
      const response = await axiosInstance.post(
        ApiRoutes.CUSTOMER_KYC_UPLOAD,
        convertJSONToFormData({ ...removeFalsyKeys(payload), reupload: false })
      );
      Notification({
        type: NotificationTypes.SUCCESS,
        message: response.data["message"],
        description: "",
      });
      return true;
    } catch (error) {}
  };

  const getVerificationCode = async (customerId: number) => {
    try {
      const response = await axiosInstance.get(
        ApiRoutes.VERIFICATION_CODE + "?user_id=" + customerId
      );
      return response.data["verification_code"];
    } catch (error) {}
  };

  const getUploadLink = async (payload: {
    extension: string;
    user_id: number;
    code: number;
  }) => {
    try {
      const response = await axiosInstance.put(
        ApiRoutes.GENERATE_UPLOAD_LINK,
        payload
      );
      return response.data["url"];
    } catch (error) {}
  };

  const setUploadSuccess = async (payload: {
    extension: string;
    user_id: number;
    status: number;
    code: number;
  }) => {
    try {
      const response = await axiosInstance.post(
        ApiRoutes.VIDEO_UPLOAD_SUCCESS,
        payload
      );
      Notification({
        type: NotificationTypes.SUCCESS,
        message: response.data["message"],
        description: "",
      });
      return true;
    } catch (error) {}
  };

  const uploadVideoDocument = async (customerId: number, video: File) => {
    const code = await getVerificationCode(customerId);

    const extension = video.name?.split(".")?.pop() || "";
    const url = await getUploadLink({
      code,
      extension,
      user_id: customerId,
    });

    const azureAxiosInstance = axios.create({
      timeout: 180000,
    });
    azureAxiosInstance.interceptors.request.use(function (config) {
      config.headers = {
        "x-ms-blob-type": "BlockBlob",
        "Access-Control-Allow-Origin": "*",
      };
      return config;
    });
    try {
      const response1 = await azureAxiosInstance.put(url, video);
      const response = await setUploadSuccess({
        code,
        status: response1?.status,
        extension,
        user_id: customerId,
      });
      return response;
    } catch (error) {}
  };

  const changePassword = async (payload: {
    current_password: string;
    password: string;
    password_confirmation: string;
  }) => {
    try {
      setChangePasswordButtonLoading(true);
      const response = await axiosInstance.put(
        ApiRoutes.CHANGE_PASSWORD,
        payload
      );
      Notification({
				message: response.statusText,
				description: response.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setChangePasswordButtonLoading(false);
			return response.status;
    } catch (error) {
    } finally {
      setChangePasswordButtonLoading(false);
    }
  };

  const getBuySharesBreakdown = async (
    customerId: number,
    option: number,
    amount?: number,
    unit?: number
  ) => {
    setLoading(true);
    setShare(new Share());
    const params = {
      option,
      amount,
      unit,
    };
    try {
      const url = generatePath(ApiRoutes.SHARES_BREAKDOWN, { customerId });
      const response = await axiosInstance.get(url, { params });
      if (response.data?.success) {
        const data = deserialize(
          ShareBreakdown,
          response.data["detailed_breakdown"]
        ) as ShareBreakdown;
        setShare(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSellSharesBreakdown = async (customerId: number, unit: string) => {
    setLoading(true);
    setShare(new Share());
    const params = {
      unit,
    };
    try {
      const url = generatePath(ApiRoutes.SHARES_SELL_BREAKDOWN, { customerId });
      const response = await axiosInstance.get(url, { params });
      if (response.data?.success) {
        const data = deserialize(
          ShareBreakdown,
          response.data["detailed_breakdown"]
        ) as ShareBreakdown;
        setShare(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const placeOrder = async (
    customerId: number,
    payload: {
      unit?: number;
      type?: boolean;
    }
  ) => {
    try {
      setLoading(true);
      const url = generatePath(ApiRoutes.PLACE_ORDER, { customerId });
      const response = await axiosInstance.post(url, payload);
      Notification({
        type: NotificationTypes.SUCCESS,
        message: response.data["message"],
        description: "",
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const rejectOrder =async (id?:number) => {
    try{
      setLoading(true);
      const url = generatePath(ApiRoutes.ORDERS_REJECT, { id });
      const response = await axiosInstance.patch(url);
      return response
    }
    catch(err){
    }
    finally{
      setLoading(false);
    }
  }

  const updateKycInfo = async (values?:KYCBasicInfo, id?:number) => {
    try {
      setLoading(true);
      const params = serialize(KYCBasicInfo, values);
      const url = generatePath(ApiRoutes.UPDATE_KYC, { id: id });
      const response = await axiosInstance.patch(url, params);
      return true
    }
    catch(err){}
    finally{
      setLoading(false)
    }
  }

  return {
    loading,
    customer,
    customers,
    approving,
    kycDetails,
    uploadVideoDocument,
    uploadDocuments,
    customerHolding,
    paymentMethod,
    walletAndHolding,
    walletHistory,
    buySellDetail,
    createMemberReport,
    fetchCustomers,
    verifyKycDocuments,
    createCustomerReport,
    updateNationalNumber,
    syncCustomerLookupData,
    fetchSingleCustomerKycDetails,
    fetchSingleCustomerBasicDetails,
    fetchPendingCustomers,
    fetchIncompleteCustomers,
    updateCustomerDetails,
    addMoneyWallet,
    fetchPaymentMethod,
    fetchCustomerBuySellOrder,
    changePassword,
    changePasswordButtonLoading,
    share,
    setShare,
    getBuySharesBreakdown,
    getSellSharesBreakdown,
    placeOrder,
    rejectOrder,
    updateKycInfo
  };
};

export default CustomerService;
