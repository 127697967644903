import React, { FC, Fragment } from "react";
import AddWalletBalance from "../../../../../shared/components/AddWalletBalance";
import { Tabs, Collapse, Space, Spin, Row, Col, TablePaginationConfig } from "antd";
import BuyIcon from "./../../../../../assets/icons/multi color icons - svg/share bought.svg";
import WithdrawIcon from "./../../../../../assets/icons/multi color icons - svg/money withdrawn.svg";
import AddIcon from "./../../../../../assets/icons/multi color icons - svg/money added.svg";
import "./customerWallet.scss";
import moment from "moment";
import AuthService from "../../../../../services/AuthService/auth.service";
import { useEffect, useState } from "react";
import { numberConvention } from "../../../../../shared/utils/numberConvention";
import { WalletHistoryTypes } from "../../../../../enums/walletHistoryTypes";
import { WalletHistory } from "../../../../../models/Wallet/wallet.model";
import { AuthState } from "../../../../../store/reducers/authReducer";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import WalletService from "../../../../../services/WalletService/wallet.service";
import TableComponent from "../../../../../shared/components/TableComponent";
import { User } from "../../../../../models/user.model";
import { Customer } from "../../../../../models/Customer/customer.model";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { WithdrawalModesObject } from "../../../../../enums/withdrawalModes";
import { AlignType } from 'rc-table/lib/interface';
import { CustomerTypes } from "../../../../../enums/customerTypes";

interface CustomerWalletProps extends AuthState{
  customer: Customer
  changeTab: (key: string) => void
}

const columns = [
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
    sorter: true,
    width: 150,
    render: (text: string) => text && moment(text).format("DD, MMM YYYY hh:mm A")
  },
  {
    title: "Amount Requested",
    key: "amount",
    dataIndex: "amountRequested",
    sorter: true,
    width: 50,
    className: "amount-column",
    align: 'right' as AlignType,
    render: (text: string) => numberConvention(text)
  },
  {
    title: "Withdraw To",
    key: "withdraw_to",
    dataIndex: "withdrawTo",
    sorter: true,
    width: 150,
    render: (text: string) => <span>{text && WithdrawalModesObject[text]}</span>
  },
  {
    title: "Account Number",
    key: "account_number",
    dataIndex: "accountNumber",
    sorter: true,
    width: 200,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    sorter: true,
    width: 100,
    render: (text: string) => <span className={`kyc--status ${text} text-capitalize`}>{text}</span>
  },
]


interface CustomerWallet extends AuthState{};
const CustomerWallet: FC<CustomerWalletProps> = (props) => {
  const history = useHistory();
  const { Panel } = Collapse;

  const { customer, changeTab,user } = props;

  const [selectedTab, setSelectedTab] = useState("dynamic");

  const customerId = history.location.pathname.split("/").pop();

  const {
    getCustomerWithdrawReqs,
    withdrawList,
    getWalletHistory,
    wallet,
    walletHistory,
    walletHistroyLoading,
    walletLoading,
    withdrawPagination,
  } = WalletService();

  const disableActionEvents: boolean = user?.role === CustomerTypes.DIGITAL;

  useEffect(() => {
    if (!customerId) return
    if (selectedTab !== "withdraw")
      getWalletHistory(customerId, selectedTab);
    else
      getCustomerWithdrawReqs(customerId)
  }, [selectedTab]);

  const { TabPane } = Tabs;

  const historyIconMapper = ({ type, details }: WalletHistory) => {
    if (WalletHistoryTypes.DEPOSIT === type) {
      return AddIcon;
    }
    if (WalletHistoryTypes.SELF === type && !!details) {
      return WithdrawIcon;
    }
    return BuyIcon;
  };

  const handleWithdrawTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null>,
    sort?: SorterResult<any> | any[]) => {
    if (!customerId) return
    const order = (sort as any)?.order
    const sortDirections: Record<string, string> = {
      'ascend': "asc",
      'descend': "desc",
    }
    if (order)
      getCustomerWithdrawReqs(customerId, { page: pagination?.current || 1, sort_dir: sortDirections[order], sort_by: (sort as any)?.columnKey })
    else
      getCustomerWithdrawReqs(customerId, { page: pagination?.current || 1 })
  }

  const walletHistoryTabsTemplate = (
    <Tabs defaultActiveKey="dynamic" onChange={setSelectedTab}>
      <TabPane disabled={walletHistroyLoading || walletLoading} tab="Dynamic Wallet History" key="dynamic"></TabPane>
      <TabPane disabled={walletHistroyLoading || walletLoading} tab="Static Wallet History" key="static"></TabPane>
      <TabPane disabled={walletHistroyLoading || walletLoading} tab="Withdraw Request" key="withdraw"></TabPane>
    </Tabs>
  );

  return (
    <Fragment>
      <div className="wallet--container">
        <AddWalletBalance wallet={wallet}
          loading={walletLoading}
          addBankDetailsHandler={() => changeTab("1")}
          customer={customer}
          fetchRequests={() => customerId && getCustomerWithdrawReqs(customerId, { page: 1 })}
          userType={user?.role}
        />
        <div className="history__container">
          {walletHistoryTabsTemplate}
          <div className={`${disableActionEvents && 'pointer-none'}`}>
          {walletHistroyLoading ? (
            <div className="loading">
              <Space size="middle" align="center" direction="vertical">
                <Spin size="large" />
              </Space>
            </div>
          ) : walletHistory.length ?
            selectedTab === "withdraw"
              ? <TableComponent
                columns={columns}
                rowKey={"id"}
                dataSource={withdrawList}
                onChange={handleWithdrawTableChange}
                loading={walletLoading}
                pagination={{
                  pageSize: withdrawPagination?.perPage || 0,
                  total: withdrawPagination?.total || 0,
                  current: withdrawPagination?.currentPage || 0,
                  showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
              />
              : (
                <Collapse accordion={true}>
                  {walletHistory.map((history, i) => (
                    <>
                      <Panel
                        /*   collapsible={history.details ? "header" : "disabled"} */
                        showArrow={false}
                        header={
                          <div className="wallet-history--item">
                            <div className="content">
                              <div className="content-icon">
                                <img
                                  src={historyIconMapper(history)}
                                  alt={history.type}
                                />
                              </div>
                              <div className="content-text">
                                <p className="content-text--title">
                                  <span>{history.title}</span>

                                  <span className="content-text--expand">
                                    details
                                  </span>
                                </p>
                                <p className="content-text--subtitle">
                                  {moment(history.createdAt).format("LLL")}
                                </p>
                              </div>
                            </div>
                            <div
                              className={
                                "actions " +
                                (history.forDynamicWallet ? "success" : "danger")
                              }
                            >
                              {numberConvention(history.amount)} KES
                            </div>
                          </div>
                        }
                        key={i}
                      >
                        {history.paymentDetails ? (
                          <Row className="footer_transaction">
                            <Col span={24} mb-5>
                              <Row>
                                {Object.entries(history.paymentDetails).map(
                                  ([key, value]) => (
                                    <Col span={24} mb-5>
                                      <label htmlFor="">{key}</label>
                                      <p>{value}</p>
                                    </Col>
                                  )
                                )}
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          <div className="panel-content">
                            <div className="panel-content--details">
                              <p className="panel-content--details--title">Units</p>
                              <p className="panel-content--details--value">
                                {history.details?.shareQuantity}
                              </p>
                            </div>
                            <div className="panel-content--details">
                              <p className="panel-content--details--title">
                                Single share value
                              </p>
                              <p className="panel-content--details--value">
                                {numberConvention(history.details?.unitPrice)} KES
                              </p>
                            </div>
                            <div className="panel-content--details">
                              <p className="panel-content--details--title">
                                Sold Value
                              </p>
                              <p className="panel-content--details--value">
                                {numberConvention(history.details?.total)} KES
                              </p>
                            </div>
                            <div className="panel-content--details">
                              <p className="panel-content--details--title">
                                Status
                              </p>
                              <p className="panel-content--details--value">
                                {history.details?.status}
                              </p>
                            </div>
                          </div>
                        )}
                      </Panel>
                    </>
                  ))}
                </Collapse>
              ) : (
              <p style={{ textAlign: "center" }}>No records found...</p>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AuthContainer(CustomerWallet);
