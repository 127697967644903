import React from "react";
import { Customer } from "../../models/Customer/customer.model";

export const getCapitalizeText = (customer: Customer) => {
  return (
    <span className="text-capitalize">{`${
      customer?.firstName?.toLowerCase() || ""
    } ${customer?.lastName?.toLowerCase() || ""} ${
      customer?.middleName?.toLowerCase() || ""
    }`}</span>
  );
};
