import { useState } from "react";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Faqs } from "../../models/Information/Faq.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const InformationService = () => {
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState<Faqs | null>(null);
    const [termsAndContions, setTermsAndConditions] =
        useState<string | null>(null);

    const getFaqs = () => {
        setLoading(true);
        setFaqs(null);
        return axiosInstance
            .get(ApiRoutes.FAQ)
            .then((response) => {
                const faqArray = deserialize(Faqs, response.data["faq"]);
                setFaqs(faqArray);
            })
            .catch((error) => { })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTermsAndConditions = () => {
        setLoading(true);
        setTermsAndConditions(null);
        return axiosInstance
            .get(ApiRoutes.TERMS_AND_CONDITIONS)
            .then((response) => {
                setTermsAndConditions(response.data["terms_and_conditions"]);
            })
            .catch((error) => { })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        getFaqs,
        faqs,
        getTermsAndConditions,
        termsAndContions,
        loading,
    };
};

export default InformationService;
