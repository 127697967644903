import { Button } from "antd";
import React, { ChangeEvent, FC, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import ErrorMessage from "../../../shared/components/Error";
import PageHeader from "../../../shared/components/PageHeader";
import { REGEX } from "../../../shared/utils/regex";
import "./refer.scss"

interface ReferProps {

}

const Refer: FC<ReferProps> = (props) => {

    const [email, setEmail] = useState<string>('')
    const [isValid, setIsValid] = useState<boolean>(true)

    const { createInvite, loading } = SubscriptionService()

    useEffect(() => {
        if (!isValid) {
            validateEmail()
        }
    }, [email])

    const validateEmail = () => {
        setIsValid(REGEX.SEPERATE_EMAIL.test(email))
    }

    const updateEmail = (val: any) => {
        setEmail(val.target.value || '')
    }

    const submitHandler = async () => {
        if (!email) { return }
        try {
            if (isValid) {
                const formattedEmail = email.replaceAll(" ", "")
                const response = await createInvite(formattedEmail)
                if (response) {
                    setEmail('')
                    setIsValid(true)
                }
            }
        } catch (error) {

        }
    }

    return (
        <div className="refer">
            <div className="refer__header">
                <PageHeader title="Invite Friends" />
            </div>
            <div className="refer__container">
                <div className="refer__container--email">
                    <p className="refer__container--email-text">Invite your friends by entering their email ids as comma seperated below</p>
                    <textarea
                        placeholder="name@mail.com, name2@mail.com, name3@mail.com ,..."
                        className="refer__container--email-field"
                        onBlur={validateEmail}
                        onChange={updateEmail}
                        value={email}
                    ></textarea>
                    {!isValid && <ErrorMessage message="Please provide a valid email"></ErrorMessage>}
                    <Button
                        type="primary"
                        className="refer__container--email-button"
                        onClick={submitHandler}
                        loading={false}
                    >Send Referral Code</Button>
                </div>
                <div className="refer__container--instructions">
                    <h3>Instructions</h3>
                    <ul>
                        <li><span>The referral invite can only be sent via email.</span></li>
                        <li><span>You will receive the referral token only when the invited user creates their account and pays their respective joining and annual fees.</span></li>
                        <li><span>The cash token will be credited into your wallet.</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Refer;