import { generatePath } from 'react-router-dom';
import { Meta } from './../../models/Meta/meta.model';
import { deserialize } from 'serializr';
import { useState } from 'react';
import axiosInstance from "../../interceptor/axiosInstance"
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes"
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { SubscriptionPaymentDetails } from '../../models/Subscription/subscription.model';

const SubscriptionService = () => {

    const [loading, setLoading] = useState(false);

    const [subscriptions, setSubscriptions] = useState<Meta[]>([])

    const [paymentDetails, setPaymentDetails] = useState<SubscriptionPaymentDetails>()

    const createInvite = async (emails: string) => {
        setLoading(true)
        try {
            const serializedData = { emails }
            const response = await axiosInstance.post(ApiRoutes.INVITE, serializedData)
            if (response.data?.success) {
                Notification({
                    message: "",
                    description: response.data?.message,
                    type: NotificationTypes.SUCCESS,
                });
                return true
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.ERROR,
            });
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchSubscriptionsMeta = async (customerId: string) => {
        try {
            const params = { user_id: customerId }
            const response = await axiosInstance.get(ApiRoutes.META_SUBSCRIPTIONS, { params })
            const subscriptions = deserialize(Meta, response.data["subscriptions"]) as Meta[];
            setSubscriptions(subscriptions)
        } catch (error) {

        } finally {
        }
    }

    const fetchSubscriptionDetails = async (params: {
        customerId: string,
        subscriptionId: string
    }) => {
        setPaymentDetails(undefined)
        try {
            const queryParams = { user_id: params.customerId, upgrade: 1 }
            const response = await axiosInstance.get(
                generatePath(ApiRoutes.SUBSCRIPTION_DETAILS,
                    { subscriptionId: params.subscriptionId }),
                { params: queryParams })
            const paymentDetails = deserialize(SubscriptionPaymentDetails, response.data['payable']);
            setPaymentDetails(paymentDetails)
        } catch (error) {

        } finally {
        }
    }

    const upgradeSubscription = async (user_id: string, subscription_id: string) => {
        setLoading(true)
        try {
            const params = { user_id, subscription_id }
            const response = await axiosInstance.put(ApiRoutes.UPGRADE_SUBSCRIPTION, { subscription_id }, { params })
            setPaymentDetails(undefined)
            return response.data.success
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        createInvite,
        subscriptions,
        paymentDetails,
        upgradeSubscription,
        fetchSubscriptionsMeta,
        fetchSubscriptionDetails,
    }
}

export default SubscriptionService