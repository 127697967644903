import { Customer } from "../../../../models/Customer/customer.model";
import { getCapitalizeText } from "../../../../shared/utils/getCapitalizeText";
import { getBlackListStatus } from "../../../../shared/utils/getBlackListStatus";

export const allCustomersColumns = [
  {
    title: "ID",
    dataIndex: "uId",
    key: "id",
    width: 100,
    sorter: true,
    render: (id: number) => `${id}`,
  },
  {
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
    width: 180,
    ellipsis: true,
    sorter: true,
    render: (_: string, customer: Customer) => getCapitalizeText(customer),
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    width: 80,
    sorter: true,
  },
  {
    title: "Member since",
    dataIndex: "joinedDate",
    key: "joinedDate",
    width: 110,
    sorter: true,
  },
  {
    title: "Account manager",
    dataIndex: "accountManager",
    key: "accountManager",
    width: 180,
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
    width: 100,
  },
  {
    title: "Subscription",
    dataIndex: "subscription",
    key: "subscription",
    width: 100,
    sorter: true,
  },
  {
    title: "Units",
    dataIndex: "holdings",
    key: "holdings",
    width: 70,
  },
  {
    title: "Wallet",
    dataIndex: "dynamicWalletBalance",
    key: "dynamicWalletBalance",
    width: 80,
  },
  {
    title: "Status",
    dataIndex: "isBlacklisted",
    key: "isBlacklisted",
    width: 70,
    render: getBlackListStatus,
  },
];
