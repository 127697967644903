export const objectToIndexedQueryString: any = function (
  obj: any,
  prefix: any
) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? objectToIndexedQueryString(v, k)
          : k + "=" + v
      );
    }
  }
  return str.join("&");
};
