import { Tabs } from "antd";
import React, { Fragment } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import "./configuration.scss"
import Dividends from "./Dividends";
import InternalUsers from "./InternalUsers";
import UnitRates from "./UnitRate";

const Configuration = () => {
    const { TabPane } = Tabs;



    const tabChangeHandler = (key: string) => {
        if (key === "1") {
        } else if (key === "2") {
        } else {

        }
    }

    return <div className="configuration__container">
        <div className="configuration__header">
            <PageHeader
                title="Configurations"
            />
        </div>
        <div className="configuration__body">
            <Tabs defaultActiveKey="1" onChange={tabChangeHandler}>
                {/* <TabPane disabled tab="Units Count" key="0" />
                <TabPane disabled tab="Bank Rate" key="2" />
                <TabPane disabled tab="FAQ" key="3" /> */}
                <TabPane tab="Units Rate" key="1" >
                    <UnitRates />
                </TabPane>
                <TabPane tab="Internal users" key="4" >
                    <InternalUsers />
                </TabPane>
                <TabPane tab="Dividends" key="5" >
                    <Dividends />
                </TabPane>
            </Tabs>

        </div>
    </div>

}

export default Configuration