import React, { FC, useEffect, useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import "./searchBar.scss"
import { Button, Dropdown } from "antd";
import FilterIcon from "./../../../assets/icons/multi color icons - svg/filter.svg"
import menu from "antd/lib/menu";
import DropdownField from "../DropdownField";
import { Formik, FormikValues } from "formik";

interface SearchBarProps {
    placeholder: string
    filters: false | Object
    onChange: (string: string) => void
}

const SearchBar: FC<SearchBarProps> = (props) => {

    const {
        placeholder,
        filters,
        onChange,
    } = props

    const [initialValues, setInitialValues] = useState<FormikValues>({})

    useEffect(() => {
        if (!filters) { return }
        const keys: any = new Object()
        Object.keys(filters).forEach(key => {
            keys[key] = ''
        })
    }, [filters])

    const menu = (
        <div className="filter__container">
            <div className="filter--header">
                <h1 className="filter--header-title">Filters</h1>
                <div className="filter--header-actions">
                    <Button>Reset</Button>
                    <Button className="primary">Apply</Button>
                </div>
            </div>
            <div className="filter--body">
                <Formik initialValues={initialValues} onSubmit={() => { }}>
                    <DropdownField name="Nishanh"></DropdownField>
                </Formik>
            </div>
        </div>
    );

    return <div className="search-bar__container">
        <div className="search-bar--input">
            <input type="text" placeholder={placeholder} onChange={e => onChange(e.target?.value || '')} />
            <SearchOutlined />
        </div>
        {/* <div className="search-bar--filter">
            <Dropdown overlay={menu} trigger={["hover"]} placement="bottomLeft">
                <Button>
                    Filters
                </Button>
            </Dropdown>
        </div> */}

    </div>
}

export default SearchBar