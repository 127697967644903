import React, { FC, useEffect, useState, CSSProperties } from "react";
import Layer1 from "../../../../assets/images/layer1.svg";
import Layer2 from "../../../../assets/images/layer2.svg";
import Logo from "../../../../assets/icons/logo - svg/vuka-logo-light.svg";
import LoginPng from "./../../../../assets/images/login.png"
import { Col, Row } from "antd";
import "./onBoarding.scss";

interface OnBoardingProps {
    styles?: CSSProperties;
}

const OnBoarding: FC<OnBoardingProps> = (props) => {
    const { children, styles } = props;
    const [makeAnimation, setMakeAnimation] = useState(true);

    // useEffect(() => {
    //         if (makeAnimation) {
    //             setMakeAnimation(false);
    //             setTimeout(() => {
    //                 setMakeAnimation(true);
    //             }, 500);
    //         } else {
    //             setMakeAnimation(true);
    //         }
    // }, []);

    return (
        <div className="on-boarding">
            <Row>
                <Col
                    span={12}
                    className={`layer-image__wrapper ${
                        makeAnimation
                            ? "move-left-to-right"
                            : "move-right-to-left"
                    } `}
                >
                    <img src={LoginPng} alt="" className="bg-image" />
                    <img src={Layer1} alt="" className="layer outer-layer" />
                    <img src={Layer2} alt="" className="layer" />
                    <div className="logo">
                        <img src={Logo} alt="" />
                        <p>Kenya's #1 Investment Portal</p>
                    </div>
                </Col>
                <Col
                    span={12}
                    style={styles}
                    className={`on-boarding__content no-scroll ${
                        makeAnimation ? "fade-in" : "fade-out"
                    }`}
                >
                    {children}
                </Col>
            </Row>
        </div>
    );
};

export default OnBoarding;
