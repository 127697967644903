import { serializable, alias, primitive } from "serializr";

export class Meta {

  @serializable(alias("name", primitive()))
  label?: string;

  @serializable(alias("id", primitive()))
  value?: string;
}
