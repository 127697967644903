import { serializable, alias, primitive, object, list } from "serializr";
import { CustomerTypes } from "../enums/customerTypes";

class Subscription {
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("code", primitive()))
  code?: string;
}

type RejectedDocumentType =
  | "verification_video"
  | "photograph"
  | "kenyan_id_proof"
  | "KRA_pin";

export class DocumentsRejected {
  @serializable(alias("document", primitive()))
  document?: RejectedDocumentType;

  @serializable(alias("rejection_reason", primitive()))
  reason?: string;
}

export class User {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("phone", primitive()))
  mobileNumber?: string;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("invite_code", primitive()))
  inviteCode?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("name", primitive()))
  username?: string;

  @serializable(alias("is_kyc_accepted", primitive()))
  kycAccepted?: string;

  @serializable(alias("rejected_documents", list(object(DocumentsRejected))))
  rejectedDocuments?: DocumentsRejected[];

  @serializable(alias("role", primitive()))
  role?: CustomerTypes;

  @serializable(alias("uid", primitive()))
  uid?: string;

  @serializable(alias("active_subscription", object(Subscription)))
  activeSubscription?: Subscription;

  @serializable(alias("member_since", primitive()))
  memberSince?: string;
  @serializable(alias("subscription", object(Subscription)))
  subscription?: Subscription;
}
