import React, { FC, ReactElement } from "react";
import { Button } from "antd";
import './buttonComponent.scss';

interface ButtonComponentProps {
    loading?: boolean;
    className?: string;
    type?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
    text?: string | ReactElement;
    htmlType?: "button" | "submit" | "reset";
    onClick?: () => void;
    disabled?: boolean;
    iconClass?: string;
}

const ButtonComponent: FC<ButtonComponentProps> = (props) => {

    const { children, text = children, iconClass, className = "" } = props;

    const buttonProps = { ...props }

    delete buttonProps['text']
    delete buttonProps['iconClass']

    return (
        <Button {...buttonProps} className={`${className} button-component`}>
            {iconClass && <span className={iconClass + " btn-icon"} />} {text}
        </Button>
    )
}

export default ButtonComponent;
