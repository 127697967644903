import { Col, Form, Row } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { ReportTypes } from '../../../enums/reportTypes.enum'
import { ReportModel } from '../../../models/ReportModel/report.model'
import CustomerService from '../../../services/CustomerService/customer.service'
import ReportService from '../../../services/ReportService/report.service'
import ButtonComponent from '../../../shared/components/ButtonComponent'
import DatePickerComponent from '../../../shared/components/DatePickerComponent'
import DropdownField from '../../../shared/components/DropdownField'
import PageHeader from '../../../shared/components/PageHeader'
import EmptyReportIcon from "./../../../assets/images/empty-reports.svg"
import { customerRoles, orderStatuses, orderTypes, reportTypes, subscriptions } from './report.data'
import "./report.scss"
import { ReportFormValidationSchema } from './reportForm.validation'

const Report = () => {

    const {
        loading: orderLoading,
        createReport,
        createInvitesReport,
        createPaymentReport,
    } = ReportService()

    const {
        loading: customerLoading,
        createCustomerReport,
        createMemberReport,
    } = CustomerService()

    const reportRequest = (reportType: ReportTypes) => {
        switch (reportType) {
            case ReportTypes.ORDER:
                return createReport
            case ReportTypes.CUSTOMER:
                return createCustomerReport
            case ReportTypes.MEMBER:
                return createMemberReport
            case ReportTypes.INVITE:
                return createInvitesReport
            case ReportTypes.PAYMENT:
                return createPaymentReport
            default:
                return () => { }
        }
    }

    const submitHandler = async (values: ReportModel, helpers: FormikHelpers<ReportModel>) => {
        if (!values.reportType) return
        const reportUrl = await reportRequest(values.reportType)(values);
        if (reportUrl) {
            helpers.resetForm()
            helpers.setFieldValue("reportType", values.reportType)
        }
    }

    return (
        <div className="report__container">
            <div className="report__header">
                <PageHeader title="Reports" />
            </div>
            <div className="report__form">
                <Formik
                    validationSchema={ReportFormValidationSchema}
                    initialValues={new ReportModel()}
                    onSubmit={submitHandler}>
                    {({ resetForm, setFieldValue, values, submitForm, setFieldTouched }) => <Form>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <DropdownField
                                    title='Report Type'
                                    options={reportTypes}
                                    placeHolder='Select'
                                    value={values.reportType}
                                    name={'reportType'}
                                    onChange={(value) => {
                                        resetForm()
                                        setFieldValue("reportType", value)
                                    }}
                                />
                            </Col>
                            <Col span={8}>
                                {(values.reportType === ReportTypes.ORDER || values.reportType === ReportTypes.CUSTOMER || values.reportType === ReportTypes.MEMBER || values.reportType === ReportTypes.PAYMENT) && <DatePickerComponent
                                    name='date'
                                    title='Date Range'
                                    range={true}
                                    rangeValue={[
                                        values.startDate ? moment(values.startDate) : null as any,
                                        values.endDate ? moment(values.endDate) : null as any,
                                    ]}
                                    rangePlaceHolder={['From Date', 'To Date']}
                                    onChange={((val, stringArray) => {
                                        setFieldValue("date", val);
                                        setFieldValue("startDate", stringArray[0] || null);
                                        setFieldValue("endDate", stringArray[1] || null)
                                    })}
                                />}
                            </Col>
                            <Col span={8}></Col>
                            {values.reportType === ReportTypes.ORDER && <Col span={8}>
                                <DropdownField
                                    title='Order Type'
                                    options={orderTypes}
                                    mode="multiple"
                                    placeHolder='Select'
                                    name={'orderTypes'} />
                            </Col>}
                            {values.reportType === ReportTypes.ORDER && <Col span={8}>
                                <DropdownField
                                    title='Order Status'
                                    options={orderStatuses}
                                    mode="multiple"
                                    placeHolder='Select'
                                    name={'orderStatuses'} />
                            </Col>}
                            {(values.reportType === ReportTypes.CUSTOMER || values.reportType === ReportTypes.MEMBER) && <Col span={8}>
                                <DropdownField
                                    title='Customer Roles'
                                    options={customerRoles}
                                    mode="multiple"
                                    placeHolder='Select'
                                    name={'roles'} />
                            </Col>}
                            {(values.reportType === ReportTypes.ORDER || values.reportType === ReportTypes.CUSTOMER || values.reportType === ReportTypes.MEMBER)
                                && <Col span={8}>
                                    <DropdownField
                                        title='Subscription'
                                        options={subscriptions}
                                        mode="multiple"
                                        placeHolder='Select'
                                        name={'subscriptions'} />
                                </Col>}
                        </Row>
                        <Row gutter={16} justify="end" className='pt-2'>
                            <Col>
                                <ButtonComponent
                                    type='primary'
                                    htmlType='submit'
                                    onClick={() => {
                                        submitForm()
                                        setFieldTouched("date")
                                    }}
                                    loading={customerLoading || orderLoading}
                                >Run Report</ButtonComponent>
                            </Col>
                            <Col>
                                <ButtonComponent
                                    className='secondary'
                                    type='default'
                                    onClick={resetForm}
                                >Cancel</ButtonComponent>
                            </Col>
                        </Row>
                    </Form>}
                </Formik>
            </div>
        </div>
    )
}

export default Report