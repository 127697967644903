import React, { FC } from "react";
import AccountManagersList from "./AccountManagerList";

interface AccountManagerProps {}

const AccountManager: FC<AccountManagerProps> = (props) => {
  return <AccountManagersList />;
};

export default AccountManager;
