import React from "react";

const allOrdersColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",

    render: (text: string) => (
      <span className="text-capitalize">{text.toLowerCase()}</span>
    ),
  },
  {
    title: "Customer Type",
    dataIndex: "customerType",
    key: "customerType",
    /*  sorter: (a: any, b: any) => a.subscription.length - b.subscription.length, */
  },
  {
    title: "Subscription",
    dataIndex: "subscription",
    key: "subscription",
    /*  sorter: (a: any, b: any) => a.subscription.length - b.subscription.length, */
  },

  {
    title: "Date Requested",
    dataIndex: "dateRequested",
    key: "dateRequested",
    sorter: (a: string, b: string) => (a < b ? -1 : 1),
  },
  {
    title: "Order type",
    dataIndex: "orderType",
    key: "id",
    sorter: (a: string, b: string) => (a < b ? -1 : 1),
  },

  {
    title: "Units",
    dataIndex: "units",
    key: "units",
    sorter: (a: string, b: string) => (a < b ? -1 : 1),
  },

  {
    title: "Order Value",
    dataIndex: "orderValue",
    key: "orderValue",
    sorter: (a: string, b: string) => (a < b ? -1 : 1),
  },

  {
    title: "Units",
    dataIndex: "holdings",
    key: "holdings",
  },

  {
    title: "Status",
    dataIndex: "isBlacklisted",
    key: "isBlacklisted",
    render: (isBlacklisted: string) =>
      !isBlacklisted ? (
        <span className="kyc--status accepted">Active</span>
      ) : (
        <span className="kyc--status rejected">Inactive</span>
      ),
  },
];

export { allOrdersColumns };
