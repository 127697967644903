import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Formik, Form } from "formik";
import DropdownField from "../../../../../shared/components/DropdownField";
import { Button, Row, Col } from "antd";
import { PaginatedCustomer } from "../../../../../models/Customer/paginatedCustomer.model";
import "../accountManagerList.scss";
import { FetchQueryParams } from "../../../../../shared/types/fetchQueryParams.type";
import { filterInitialValues } from "../../../../../shared/constants/filterInitialValues.data";

interface FilterFormProps {
  customers?: PaginatedCustomer;
  handleVisibleChange: () => void;
  filterSearch: FetchQueryParams;
  setfilterSearch: Dispatch<SetStateAction<FetchQueryParams>>;
}

const FilterForm: FC<FilterFormProps> = (props) => {
  const { customers, handleVisibleChange, filterSearch, setfilterSearch } =
    props;

  const [filterData, setFilterData] = useState<any>({});

  const handleFilterReset = () => {
    setFilterData({});
    setfilterSearch(filterInitialValues);
    handleVisibleChange();
  };

  const handleApplyFilter = () => {
    setfilterSearch({
      ...filterSearch,
      filters: filterData,
    });
    handleVisibleChange();
  };

  const formikProps = {
    initialValues: {},
    onSubmit: () => {},
  };

  return (
    <div className="filterPopup">
      <Formik {...formikProps}>
        <Form className="account-manager__form">
          <div className="styled-modal-btn">
            <Row justify="end">
              <Col span={14}>
                {" "}
                <h3>Filters</h3>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  className="account-manager__reset-btn"
                  htmlType="reset"
                  size="middle"
                  onClick={handleFilterReset}
                >
                  Reset
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="account-manager__submit-btn"
                  onClick={handleApplyFilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            {customers?.availableFilters && Object.keys(customers?.availableFilters).map((key) => {
              return (
                <Col span={12}>
                  <DropdownField
                    value={filterData[key]}
                    name={"filters." + key}
                    title={key}
                    mode={
                      customers?.availableFilters?.[key].is_multi_select
                        ? "multiple"
                        : undefined
                    }
                    placeHolder="Select"
                    showArrow
                    options={customers?.availableFilters?.[key]?.values?.map(
                      (value) => ({
                        value,
                        label: value,
                      })
                    )}
                    onChange={(value) => {
                      setFilterData({
                        ...filterData,
                        [key]: value,
                      });
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Form>
      </Formik>
    </div>
  );
};

export default FilterForm;
