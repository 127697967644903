import React, { Fragment, useEffect, useState } from "react";
import CustomerService from "../../../services/CustomerService/customer.service";
import PageHeader from "../../../shared/components/PageHeader";
import TableComponent from "../../../shared/components/TableComponent";
import CustomersList from "./CustomerList";
import "./customers.scss"

const Customers = () => {


    return <CustomersList />
}

export default Customers