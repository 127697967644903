import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import React, { FC, useRef, useState } from 'react'
import CustomerService from '../../../services/CustomerService/customer.service'
import "./uploadComponent.scss"

interface UploadComponentProps {
    customerId: number
    keyName?: string
    accept?: "document" | "video"
    refreshData: Function
}

const UploadComponent: FC<UploadComponentProps> = (props) => {

    const {
        accept = "document",
        customerId,
        keyName,
        refreshData,
    } = props

    const {
        uploadDocuments,
        uploadVideoDocument,
    } = CustomerService()

    const [loading, setLoading] = useState<boolean>()

    const filePicker = useRef<HTMLInputElement>(null)

    const handleFileChange = async (files: FileList | null) => {
        if (!files || !files[0]) return
        setLoading(true)

        const response = keyName === "video"
            ? await uploadVideoDocument(customerId, files[0])
            : await uploadDocuments({
                user_id: customerId,
                kenyan_id_proof: keyName === "kenyanIdProof" ? files[0] : undefined,
                photograph: keyName === "photograph" ? files[0] : undefined,
                KRA_pin: keyName === "kraPin" ? files[0] : undefined,
            })

        response && refreshData()
        setLoading(false)
    }

    return (
        <div className="upload-component">
            <input type="file"
                multiple={false}
                hidden
                ref={filePicker}
                accept={accept === "document" ? "image/*,application/pdf" : "video/*"}
                onChange={e => {
                    e.stopPropagation()
                    handleFileChange(e.target.files)
                }} />
            {loading
                ? <LoadingOutlined />
                : <UploadOutlined onClick={e => {
                    e.stopPropagation()
                    filePicker.current?.click()
                }} />}
        </div>
    )
}

export default UploadComponent