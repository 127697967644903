import React, { FC } from "react";
import { Select, SelectProps } from "antd";
import "./dropdownField.scss";
import { Field, ErrorMessage } from "formik";
import Error from "../Error";

interface DropdownFieldProps extends SelectProps<any> {
  name: string;
  options?: any[];
  title?: string;
  setFieldTouched?: Function;
  onChange?: (value: any, option: any) => void;
  onSearch?: (value: any) => void;
  onSelect?: (value: any) => void;
  placeHolder?: string;
  showSearch?: boolean;
  showArrow?: boolean;
  loading?: boolean;
  darkmode?: boolean;
}

const DropdownField: FC<DropdownFieldProps> = (props, { className }) => {
  const {
    name,
    title,
    placeHolder,
    onChange,
    showSearch,
    onSearch,
    onSelect,
    loading = false,
    darkmode,
  } = props;

  const handleChange = (value: any, setFieldValue: any) => {
    if (setFieldValue) setFieldValue(name, value);
  };

  const handleFilter = (input: string, option: any) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Field name={name}>
      {({ form: { setFieldTouched, setFieldValue }}: any) => {
        return (
          <div
            className={`dropdown-field mb-4 ${darkmode && "dropdown-field-dark"
              } ${className}`}
          >
            {title && <div className="dropdown-field__title">{title}</div>}
            <Select
              {...props}
              placeholder={placeHolder}
              onBlur={() => setFieldTouched(name)}
              onChange={onChange ? onChange : (val) => handleChange(val, setFieldValue)}
              onSearch={onSearch ? onSearch : undefined}
              onSelect={onSelect ? onSelect : undefined}
              filterOption={showSearch ? handleFilter : undefined}
              dropdownMatchSelectWidth={false}
              loading={loading}
              autoClearSearchValue={false}
            />
            <ErrorMessage name={name}>
              {(message: string) => (
                <Error className={`${name}__error`} message={message} />
              )}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
};

export default DropdownField;
