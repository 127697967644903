import * as Yup from "yup";
import { REGEX } from "../../utils/regex";

export const changePasswordValidation = Yup.object().shape({
  "currentPassword": Yup.string()
    .min(6, "Password has to be longer than 6 characters! ")
    .required("Current Password is required!")
    .matches(
      REGEX.PASSWORD,
      "One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  "password": Yup.string()
    .min(6, "Password has to be longer than 6 characters! ")
    .required("New Password is required!")
    .matches(
      REGEX.PASSWORD,
      "One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  "passwordConfirmation": Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
});
