import { Col, Form, Modal, Row } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { InternalUser, InternalUserCreatableRoles } from "../../../../../models/InternalUser/internalUser.modal";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import RadioField from "../../../../../shared/components/RadioField";
import "./internalUserForm.scss"
import { internalUsersValidationSchema } from "./InternalUserValidation";

interface InternalUserFormProps {
    visible: boolean;
    loading: boolean;
    title: string;
    userRoles: InternalUserCreatableRoles[]
    closeHandler: () => void;
    data?: InternalUser
    submitHandler: (values: FormikValues, resetForm: Function) => void
}

const InternalUserStatuses = [
    {
        label: "Active",
        value: 0
    },
    {
        label: "Inactive",
        value: 1
    },
]
const InternalUserForm = (props: InternalUserFormProps) => {
    const {
        visible,
        loading,
        title,
        closeHandler,
        userRoles,
        submitHandler,
        data,
    } = props;



    return (
        <Formik enableReinitialize
            initialValues={data || new InternalUser()}
            validationSchema={internalUsersValidationSchema}
            onSubmit={() => { }}
        >
            {({ setFieldValue, values, dirty, resetForm, isValid, setFieldTouched }) => {
                return (
                    <Modal
                        className="internal-user__modal"
                        visible={visible}
                        okText={data?.id ? "Update" : "Create"}
                        confirmLoading={loading}
                        onOk={() => submitHandler(values, resetForm)}
                        onCancel={closeHandler}
                        destroyOnClose
                        title={title}
                    >
                        <Form>
                            <div className="internal-user-form__container">
                                <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <DropdownField
                                            title="Type"
                                            optionLabelProp="label"
                                            placeHolder={"Type"}
                                            name="role"
                                            options={userRoles}
                                            showSearch={false}
                                            value={values.role}
                                            setFieldTouched={() => setFieldTouched('role')}
                                            onChange={(value) => {
                                                setFieldValue("role", value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <InputField
                                            title="Name"
                                            placeholder={"Name"}
                                            name="name"
                                            type="text"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <InputField
                                            title="Email ID"
                                            placeholder={"Email ID"}
                                            name="email"
                                            type="email"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <InputField
                                            addonBefore="+254"
                                            title="Mobile Number"
                                            placeholder={"Mobile Number"}
                                            name="phone"
                                            type="number"
                                        />
                                    </Col>
                                </Row>
                                {!data?.id && <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <InputField
                                            title="Password"
                                            placeholder={"Password"}
                                            name="password"
                                            type="text"
                                        />
                                    </Col>
                                </Row>}
                                {data?.id && <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <RadioField
                                            options={InternalUserStatuses}
                                            title="Status"
                                            name="isBlacklisted"
                                            value={values.isBlacklisted}
                                            setFieldValue={(name, value) => {
                                                setFieldValue(name, value)
                                            }}></RadioField>
                                    </Col>
                                </Row>}
                                {/* <Row>
                                    <Col sm={24} className="internal-user__field">
                                        <DropdownField
                                            title="Status"
                                            optionLabelProp="label"
                                            placeHolder={"Status"}
                                            name="status"
                                            options={[
                                                { label: 'Hey', value: "hey" },
                                                { label: 'Hey2', value: "hey2" },
                                                { label: 'Hey3', value: "hey3" },
                                                { label: 'Hey4', value: "hey4" },
                                            ]}
                                            showSearch={false}
                                            value={values.isBlacklisted}
                                            setFieldTouched={() => setFieldTouched('isBlacklisted')}
                                            onChange={(value) => {
                                                setFieldValue("isBlacklisted", value);
                                            }}
                                        />
                                    </Col>
                                </Row> */}


                            </div>
                        </Form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

export default InternalUserForm;
