import { Order } from './../../models/Orders/orders.model';
import { deserialize } from 'serializr';
import { useState } from 'react';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import axiosInstance from "../../interceptor/axiosInstance"

const OrderService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [orders, setOrders] = useState<Order[]>([])

    const fetchOrders = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(ApiRoutes.ORDERS, { params: { type: "buy" } })
            const data = deserialize(Order, response.data?.orders?.data) as Order[]
            setOrders(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        orders,
        fetchOrders,
    }
}
export default OrderService