type Name = "vuka-token" | "has-accepted-terms-and-conditions";
type Values = string | boolean;

const sensitive: Name[] = ["vuka-token", "has-accepted-terms-and-conditions"];
export default class LocalStorage {
    static getItem(name: "vuka-token"): string;
    static getItem(name: "has-accepted-terms-and-conditions"): boolean;
    static getItem(name: Name) {
        const value = localStorage.getItem(name);
        if (value) {
            return JSON.parse(value);
        }
    }

    static setItem(name: "vuka-token", value: string): void;
    static setItem(
        name: "has-accepted-terms-and-conditions",
        value: boolean
    ): void;
    static setItem(name: Name, value: Values) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static removeItem(name: Name) {
        localStorage.removeItem(name);
    }

    static clear() {
        localStorage.clear();
    }

    static clearSenstive() {
        sensitive.forEach((key) => {
            this.removeItem(key);
        });
    }
}
