import { ReportTypes } from './../../../enums/reportTypes.enum';
export const reportTypes = [
    { label: "Orders", value: ReportTypes.ORDER },
    { label: "Customers", value: ReportTypes.CUSTOMER },
    { label: "Members", value: ReportTypes.MEMBER },
    { label: "Invites", value: ReportTypes.INVITE },
    { label: "Payment Log", value: ReportTypes.PAYMENT },
]

export const orderTypes = [
    { label: "Buy", value: "Buy" },
    { label: "Sell", value: "Sell" },
]

export const subscriptions = [
    { label: "Silver", value: "Silver" },
    { label: "Gold", value: "Gold" },
    { label: "Platinum", value: "Platinum" },
    { label: "Chama", value: "Chama" },
    { label: "Chama Platinum", value: "Chama Platinum" },
    { label: "Platinum +", value: "Platinum +" },
    { label: "Diamond", value: "Diamond" },
]

export const orderStatuses = [
    { label: "Payment Pending", value: "Payment Pending" },
    { label: "Pending", value: "Pending" },
    { label: "Partially Fulfilled", value: "Partially Fulfilled" },
    { label: "Fulfilled", value: "Fulfilled" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Payment Failure", value: "Payment Failure" },
]


export const customerRoles = [
    { label: "Admin", value: "Admin" },
    { label: "Root", value: "Root" },
    { label: "Individual", value: "Individual" },
    { label: "Chama", value: "Chama" },
    { label: "Mpesa", value: "Mpesa" },
    { label: "RootShare", value: "RootShare" },
    { label: "DSE", value: "DSE" },
    { label: "ISA", value: "ISA" },
]