import { ActionProps } from "../../shared/types/action.type";
import { SHOW_TERMS_AND_CONDITIONS, ACCEPT_TERMS_AND_CONDITIONS, REJECT_TERMS_AND_CONDITIONS, SHOW_TERMS_AND_CONDITIONS_WITHOUT_PROMPT } from "../definitions/termsAndConditionsConstants";

export const showTermsAndConditions = (): ActionProps => ({
    type: SHOW_TERMS_AND_CONDITIONS,
    payload: {
        show: true,
        noPrompt: false
    }
})

export const showTermsAndConditionsWithoutPrompt = (): ActionProps => ({
    type: SHOW_TERMS_AND_CONDITIONS_WITHOUT_PROMPT,
    payload: {
        show: true,
        noPrompt: true
    }
})

export const acceptTermsAndConditions = (): ActionProps => ({
    type: ACCEPT_TERMS_AND_CONDITIONS,
    payload: {
        accept: true,
        show: false,
        noPrompt: false
    }
})

export const rejectTermsAndConditions = (): ActionProps => ({
    type: REJECT_TERMS_AND_CONDITIONS,
    payload: {
        accept: false,
        show: false,
        noPrompt:false
    }
})