import { serializable, alias, primitive, object, list } from "serializr";

export class CustomerPayment {
  @serializable(alias("user_id", primitive()))
  userId?: string;

  @serializable(alias("amount", primitive()))
  amount?: string;

  @serializable(alias("payment_method_id", primitive()))
  paymentMethodId?: number;
  @serializable(alias("transaction_ref", primitive()))
  transactionRef?: string;
  @serializable(alias("comment", primitive()))
  comment?: string;
}

export class CustomerPaymentMethod {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("code", primitive()))
  code?: string;
}
