import { alias, list, object, primitive, serializable } from "serializr";
import { WalletHistoryTypes } from "../../enums/walletHistoryTypes";

export class Faq {
    @serializable(alias("question", primitive()))
    question?: string;

    @serializable(alias("answer", primitive()))
    answer?: string;
}
export class Faqs {
    @serializable(alias("current_page", primitive()))
    currentPage?: number;

    @serializable(alias("data", list(object(Faq))))
    faqs?: Faq[];

    @serializable(alias("last_page", primitive()))
    totalPages?: number;
}
