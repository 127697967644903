import { alias, primitive, serializable } from "serializr";

export class Dividend {

    @serializable(alias("price", primitive()))
    price?: number

    @serializable(alias("name", primitive()))
    name?: string

    @serializable(alias("otp", primitive()))
    otp?: string

    @serializable(alias("date_of_record", primitive()))
    date?: string

    @serializable(alias("created_at", primitive()))
    createdDate?: string

}