import { serializable, alias, primitive } from "serializr";

export class InternalUserPage {
  @serializable(alias("per_page", primitive()))
  perPage?: number;
  @serializable(alias("total", primitive()))
  total?: number;
  @serializable(alias("current_page", primitive()))
  currentPage?: number;
}

export class InternalUser {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("uid", primitive()))
  uId?: string;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("role", primitive()))
  role?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("is_blacklisted", primitive()))
  isBlacklisted?: 0 | 1;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("total", primitive()))
  total?: string;
}

export class InternalUserCreatableRoles {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("code", primitive()))
  value?: string;

  @serializable(alias("name", primitive()))
  label?: string;
}
