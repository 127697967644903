import { SharePrice } from './../../models/SharePrice/sharePrice.model';
import { NotificationTypes } from './../../enums/notificationTypes';
import { Dividend } from './../../models/Dividend/dividend.model';
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "./../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import Notification from '../../shared/components/Notification';
import {
  InternalUser,
  InternalUserCreatableRoles,
  InternalUserPage,
} from "../../models/InternalUser/internalUser.modal";

const ConfigurationService = () => {
  const [internalUsers, setInternalUsers] = useState<InternalUser[]>([]);
  const [dividends, setDividends] = useState<Dividend[]>([]);
  const [sharePrices, setSharePrices] = useState<SharePrice[]>([]);
  const [pageData, setPageData] = useState<InternalUserPage>();
  // const [dividendPageData, setDividendPageData] = useState<InternalUserPage>();
  const [internalUsersCreatableRoles, setInternalUsersCreatableRoles] =
    useState<InternalUserCreatableRoles[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const fetchCreatableUserMeta = async () => {
    setInternalUsersCreatableRoles([]);
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.CONFIGURATION_CREATABLE_USERS
      );
      const data = deserialize(
        InternalUserCreatableRoles,
        response.data["roles"]
      ) as InternalUserCreatableRoles[];
      setInternalUsersCreatableRoles(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchInternalUsers = async (
    params = {
      page: "",
      search: "",
    }
  ) => {
    setInternalUsers([]);
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.CONFIGURATION_INTERNAL_USERS,
        { params }
      );
      const data = deserialize(
        InternalUser,
        response.data["internal_users"]["data"]
      ) as InternalUser[];
      setInternalUsers(data);

      const pagedata = deserialize(
        InternalUserPage,
        response.data["internal_users"]
      ) as InternalUserPage;

      setPageData(pagedata);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createInternalUser = async (data: InternalUser) => {
    setLoading(true);
    const serializedData = serialize(InternalUser, data);
    try {
      await axiosInstance.post(
        ApiRoutes.CONFIGURATION_INTERNAL_USERS,
        serializedData
      );
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateInternalUser = async (data: InternalUser) => {
    setLoading(true);
    const serializedData = serialize(InternalUser, data);
    try {
      await axiosInstance.put(
        ApiRoutes.CONFIGURATION_INTERNAL_USERS_SPECIFIC.replace(
          ":userId",
          String(data.id)
        ),
        serializedData
      );
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createDividend = async (dividend: Dividend) => {
    try {
      setSubmitting(true)
      const payload = serialize(Dividend, dividend)
      const response = await axiosInstance.post(ApiRoutes.DIVIDENDS, payload)
      Notification({
        message: response.data["message"] || "Dividend created",
        description: "",
        type: NotificationTypes.SUCCESS
      })
      return true
    } catch (error) {

    } finally {
      setSubmitting(false)
    }
  }

  const fetchDividends = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(ApiRoutes.DIVIDENDS)
      const dividends = deserialize(Dividend, response.data["dividends"]["data"]) as Dividend[]
      setDividends(dividends)
      // setDividendPageData(response.data["dividends"])
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const fetchUnitRates = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(ApiRoutes.UNIT_RATE)
      const dividends = deserialize(SharePrice, response.data["share_price"]["data"]) as SharePrice[]
      setSharePrices(dividends)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  const createUnitRates = async (share: SharePrice) => {
    try {
      setSubmitting(true)
      const payload = serialize(SharePrice, share)
      const response = await axiosInstance.post(ApiRoutes.UNIT_RATE, payload)
      setSharePrices(dividends)
      return true
      // setDividendPageData(response.data["dividends"])
    } catch (error) {

    } finally {
      setSubmitting(false)
    }
  }

  const fetchOtp = async (effective_date: string, price: number, isUnitPrice: boolean) => {
    try {
      setSubmitting(true)
      const payload = {
        effective_date,
        price,
        configure_type: isUnitPrice ? 1 : 0
      }
      const response = await axiosInstance.post(ApiRoutes.CONFIGURATION_OTP, payload)
      Notification({
        message: response.data["message"] || "Report created",
        description: "",
        type: NotificationTypes.SUCCESS
      })
      return true
    } catch (error) {

    } finally {
      setSubmitting(false)
    }
  }

  return {
    loading,
    submitting,
    internalUsers,
    fetchOtp,
    pageData,
    dividends,
    sharePrices,
    fetchUnitRates,
    createDividend,
    fetchDividends,
    createUnitRates,
    // dividendPageData,
    internalUsersCreatableRoles,
    fetchInternalUsers,
    createInternalUser,
    updateInternalUser,
    fetchCreatableUserMeta,
  };
};

export default ConfigurationService;
